app.directive('reviewStars', [
  function () {
    return {
      scope: {
        score: '=',
        totalScore: '=',
      },
      template:
        '<span class="{{className}}" ng-repeat="className in classNames track by $index"></span>',
      link: function (scope) {
        scope.classNames = _.range(scope.totalScore).map(function (i) {
          var starValue = scope.score - i;
          if (starValue >= 1) {
            return 'fa fa-star';
          }
          if (starValue > 0 && starValue < 1) {
            return 'fa fa-star-half-o';
          }
          return 'fa fa-star-o';
        });
      },
    };
  },
]);
