/**
 * Directive to choose currency
 **/
app.directive('slCurrencyChooser', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'C',
      scope: {},
      link: function (scope, element) {
        element.on('click', '.sl-currency-chooser-currency', function (event) {
          event.preventDefault();
          var currency = $(event.currentTarget).attr('href').slice(1);
          $rootScope.changeCurrency(currency);
        });
        element.on('$destroy', function () {
          element.off(); // Unbind events
        });
      },
    };
  },
]);
