import './services.product-set';
import './services.feature';
import './services.tracker';
import './services.cart';
import './service.back-in-stock';
import './services.wishlist';
import './services.pnotify';
app.controller('ProductWishlistController', [
  '$rootScope',
  '$scope',
  '$filter',
  'pnotifyService',
  'wishlistService',
  'backInStockService',
  'mainConfig',
  'cartService',
  'trackerService',
  'featureService',
  '$element',
  '$cookies',
  'productSetService',
  function (
    $rootScope,
    $scope,
    $filter,
    pnotifyService,
    wishlistService,
    backInStockService,
    mainConfig,
    cartService,
    trackerService,
    featureService,
    $element,
    $cookies,
    productSetService,
  ) {
    var state = {
      wishlistLoading: false,
      backInStockLoading: false,
    };
    var successAddWishListMessage = $filter('translate')(
      'back_in_stock.subscibe.success.message',
    );
    var successAddBackInStockMessage = $filter('translate')(
      'back_in_stock.subscibe.success.message',
    );

    $scope.wishlistItem = null;
    //for directives
    $scope.isItemInWishlist = null;
    $scope.isItemInBackInStock = null;

    $scope.shouldShowBackInStockBtn = function () {
      return backInStockService.shouldShowBtn(
        $scope.product,
        $scope.variationSelected,
      );
    };

    $scope.getTargetWishlistItem = function () {
      if (!$rootScope.isUserLoggedIn) {
        return;
      }
      var userId = mainConfig.currentUser
        ? mainConfig.currentUser['_id']
        : null;
      var merchantId = mainConfig.merchant_id;
      var productId = $scope.product._id;
      var variationKey = $scope.variationSelected
        ? $scope.variationSelected.key
        : '';

      //return a promise knowing when $scope.wishListItem updated
      return wishlistService
        .getList({
          product_id: productId,
          merchant_id: merchantId,
          user_id: userId,
        })
        .then(function (res) {
          var wishlistItems = res.data.items;

          //filter variation
          if (!_.isEmpty(variationKey)) {
            $scope.wishlistItem = _.filter(wishlistItems, function (item) {
              return item.variation_key === variationKey;
            })[0];
          } else if (productSetService.isProductSetEnabled($scope.product)) {
            // if product set
            var selectedProductSetParams =
              productSetService.generateProductSetParams(
                $scope.selectedProductSetData,
              );
            $scope.wishlistItem = wishlistItems.find(function (item) {
              return item.product_set_datas?.every(
                function (childProduct, index) {
                  return (
                    childProduct.child_product_id ===
                      selectedProductSetParams[index].child_product_id &&
                    childProduct.child_variation_id ===
                      selectedProductSetParams[index].child_variation_id
                  );
                },
              );
            });
          } else if (
            productSetService.isProductSetRevampEnabled($scope.product)
          ) {
            // if product set
            $scope.wishlistItem = wishlistItems.find(function (item) {
              return item.product_set_datas?.every(
                function (childProduct, index) {
                  return _.matches({
                    child_product_id:
                      $scope.selectedProductSetData[index]?.child_product_id,
                    child_variation_id:
                      $scope.selectedProductSetData[index]?.child_variation_id,
                    quantity: $scope.selectedProductSetData[index]?.quantity,
                  })(childProduct);
                },
              );
            });
          } else {
            $scope.wishlistItem = wishlistItems[0];
          }
        });
    };

    $scope.toggleBackInStock = function () {
      if (!$rootScope.isUserLoggedIn) {
        // show login tool tip
        angular.element(document).on('click.backInStockTooltip', function () {
          if ($element.find('.Tooltip-backInStockTooltip').length) {
            angular
              .element('.Tooltip-backInStockTooltip.is-tooltip-show')
              .fadeOut()
              .removeClass('is-tooltip-show');
          } else {
            angular.element(document).off('click.backInStockTooltip');
          }
        });

        $element.find('.Tooltip-backInStockTooltip').fadeIn(300, function () {
          $element
            .find('.Tooltip-backInStockTooltip')
            .addClass('is-tooltip-show');
        });
      } else if (state.backInStockLoading === false) {
        state.backInStockLoading = true;
        var productId = $scope.product._id;
        var data = {
          product_id: productId,
          variation_key: $scope.variationSelected
            ? $scope.variationSelected.key
            : '',
        };
        if (productSetService.isProductSetEnabled($scope.product)) {
          data.product_set_datas = productSetService.generateProductSetParams(
            $scope.selectedProductSetData,
          );
        }
        if (productSetService.isProductSetRevampEnabled($scope.product)) {
          data.product_set_datas = $scope.selectedProductSetData;
        }
        $scope.getTargetWishlistItem().then(function () {
          // create flow
          if (_.isEmpty($scope.wishlistItem)) {
            data.is_subscribed_notify = true;

            return wishlistService
              .addItem(data)
              .then(function () {
                $scope.isItemInWishlist = true;
                pnotifyService.notify(successAddBackInStockMessage, {});
              })
              .finally(function () {
                $scope.getTargetWishlistItem();
                state.backInStockLoading = false;
              });
          }

          // update or remove flow (subscribe/unsubscribe back in stock)
          data.id = $scope.wishlistItem._id;
          data.is_subscribed_notify = !$scope.wishlistItem.is_subscribed_notify;

          return wishlistService
            .updateItem(data)
            .then(function (res) {
              if (res.data.is_subscribed_notify) {
                $scope.isItemInWishlist = true;
                pnotifyService.notify(successAddBackInStockMessage, {});
              }
            })
            .finally(function () {
              $scope.getTargetWishlistItem();
              state.backInStockLoading = false;
            });
        });
      }
    };

    $scope.toggleWishlistItem = function () {
      if (!$rootScope.isUserLoggedIn) {
        angular.element(document).on('click.wishlistTooltip', function () {
          if ($element.find('.Tooltip-wishlistTooltip').length) {
            angular
              .element('.Tooltip-wishlistTooltip.is-tooltip-show')
              .fadeOut()
              .removeClass('is-tooltip-show');
          } else {
            angular.element(document).off('click.wishlistTooltip');
          }
        });

        $element.find('.Tooltip-wishlistTooltip').fadeIn(300, function () {
          $element.find('.Tooltip-wishlistTooltip').addClass('is-tooltip-show');
        });
      } else if (state.wishlistLoading === false) {
        state.wishlistLoading = true;
        var productId = $scope.product._id;
        var data = {
          product_id: productId,
          variation_key: $scope.variationSelected
            ? $scope.variationSelected.key
            : '',
        };

        if (productSetService.isProductSetEnabled($scope.product)) {
          data.product_set_datas = productSetService.generateProductSetParams(
            $scope.selectedProductSetData,
          );
        }

        if (productSetService.isProductSetRevampEnabled($scope.product)) {
          data.product_set_datas = $scope.selectedProductSetData;
        }

        // remove flow
        if (!_.isEmpty($scope.wishlistItem)) {
          trackerService.removeFromWishlist($scope.product);
          if (
            productSetService.isProductSetEnabled($scope.product) ||
            productSetService.isProductSetRevampEnabled($scope.product)
          ) {
            data.id = $scope.wishlistItem._id;
          }
          return wishlistService.removeItem(data).finally(function () {
            $scope.getTargetWishlistItem();
            state.wishlistLoading = false;
          });
        }

        //create flow
        var value = cartService.getItemPrice({
          product: $scope.product,
          variation: $scope.variationSelected,
        });
        trackerService.addToWishlist(
          $scope.product,
          value == null ? 0 : value.dollars,
          $scope.variationSelected,
        );

        if (
          featureService.hasFeature('back_in_stock_notify') &&
          $scope.shouldShowBackInStockBtn()
        ) {
          data.is_subscribed_notify = true;
        }

        return wishlistService
          .addItem(data)
          .then(function () {
            $scope.getTargetWishlistItem();
            if (
              featureService.hasFeature('back_in_stock_notify') &&
              $scope.shouldShowBackInStockBtn()
            ) {
              pnotifyService.notify(successAddWishListMessage, {});
            }
          })
          .finally(function () {
            state.wishlistLoading = false;
          });
      }
    };

    $scope.toLoginPage = function (e, loginPath) {
      e.preventDefault();
      e.stopPropagation();
      const wishListItem = {
        product_id: $scope.product._id,
        variation_key: $scope.variationSelected
          ? $scope.variationSelected.key
          : '',
      };
      if (
        productSetService.isProductSetEnabled($scope.product) ||
        productSetService.isProductSetRevampEnabled($scope.product)
      ) {
        wishListItem.product_set_datas = $scope.selectedProductSetData;
      }
      $cookies.put('cookieWishlistItem', JSON.stringify(wishListItem));
      window.location.href = loginPath;
    };
  },
]);
