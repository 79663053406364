import './services.ga';
import './services.slpixel';
import './services.product';
app.directive('pageItemProducts', [
  '$compile',
  'productService',
  '$rootScope',
  '$window',
  'slPixelService',
  'gaService',
  function (
    $compile,
    productService,
    $rootScope,
    $window,
    slPixelService,
    gaService,
  ) {
    return {
      restrict: 'AE',
      scope: true,
      link: function (scope, element, attrs) {
        scope.state = {
          loading: false,
        };
        scope.pagination = {
          currentPage: 1,
          total: 0,
          itemsPerPage: 24,
        };
        scope.category = null;

        if (attrs.itemsPerPage) {
          scope.pagination.itemsPerPage = parseInt(attrs.itemsPerPage, 10);
        }

        if (typeof attrs.initCategory === 'string') {
          scope.category = angular.fromJson(attrs.initCategory);
          scope.pagination.total = scope.category.count;
        }

        scope.isHiddenProductVisible = scope.$eval(
          attrs.isHiddenProductVisible,
        );
        function getProducts(page) {
          var params = {};
          _.each(
            window.location.search.replace(/.*\?/, '').split('&'),
            function (chunk) {
              var parts = chunk.split('=');
              if (parts.length == 2) {
                params[parts[0]] = parts[1];
              }
            },
          );
          var options = angular.extend(params, {
            limit: scope.pagination.itemsPerPage,
            page: page,
            format: 'html',
            col_class: attrs.colClass,
            is_quick_cart: attrs.isQuickCart,
            open_link_in_new_tab: attrs.openLinkInNewTab,
            'statuses[]': scope.isHiddenProductVisible
              ? ['active', 'hidden']
              : ['active'],
          });
          productService
            .getByCategory(scope.category._id, options)
            .then(function (data) {
              var $dummyWrapper = $(
                "<div class='dummy-container'></div>",
              ).append(data);
              // Make sure the response contains a list of product-item in the first level
              if (
                $dummyWrapper.find('.product-item').length <= 0 ||
                !$dummyWrapper
                  .find('.product-item')
                  .parent()
                  .hasClass('dummy-container')
              ) {
                throw 'Unexpected response format';
              }
              element
                .find('.product-items-container')
                .html($compile(data)(scope));
              gaService.sendPageView();
              slPixelService.sendProductImpressions();
              $rootScope.$broadcast('shop.currency.reload');
            })
            .catch(function () {})
            .finally(function () {
              $jq('.Product-item .Product-info .Product-title').dotdotdot({
                wrap: 'letter',
                ellipsis: '...',
                height: 48,
              });
              scope.state.loading = false;
            });
        }

        scope.onPageChanged = function (page) {
          if (
            (page - 1) * scope.pagination.itemsPerPage <
            scope.category.count
          ) {
            scope.state.loading = true;
            $rootScope.scrollTo(element.parent());
            setTimeout(function () {
              gaService.clearImpressions();
              slPixelService.clearImpressions();
              getProducts(page);
            }, 300);
          }
        };
      },
    };
  },
]);
