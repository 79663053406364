app.service('buyNowService', [
  'mainConfig',
  'slFeatureService',
  '$rootScope',
  '$uibModal',
  function (mainConfig, slFeatureService, $rootScope, $uibModal) {
    this.themeKey = mainConfig.merchantData.current_theme_key;
    this.isEnabled = function () {
      return (
        slFeatureService.hasFeature('buy_now_button') &&
        mainConfig.themeSettings.buy_now_button &&
        mainConfig.themeSettings.buy_now_button.enabled
      );
    };
    this.shouldShowBuyNowModal = function () {
      if (!this.isEnabled()) return;
      if (
        this.themeKey === 'bianco' &&
        window.matchMedia('(max-width: 991px)').matches
      ) {
        return true;
      }
    };
    this.openModal = function () {
      switch (this.themeKey) {
        case 'bianco':
          return this.openBiancoModal();
      }
    };
    this.openBiancoModal = function () {
      var isSpbEnabled = false;
      // select bianco hidden spb btn render by backend
      if ($('.products.show').length) {
        isSpbEnabled = $(
          '.product-detail-button-container paypal-fast-checkout-btn',
        ).length;
      } else {
        isSpbEnabled = $('.QuickCart-modal paypal-fast-checkout-btn').length;
      }
      var isBuyNowEnabled = this.isEnabled();
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/shared/bianco/templates.product_buy_now_modal.html'),
        windowClass: 'Theme-modal buy-now-modal',
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            $scope.isSpbEnabled = isSpbEnabled;
            $scope.isBuyNowEnabled = isBuyNowEnabled;
            $scope.buyNowLoading = false;
            $scope.closeModal = function () {
              $uibModalInstance.dismiss('cancel');
            };
            $scope.buyNow = function () {
              $scope.buyNowLoading = true;
              $rootScope.$broadcast('clicked_buy_now_button');
            };
            $scope.addItemToCart = function () {
              $rootScope.$broadcast('biancoModalFastCheckout');
            };
          },
        ],
      });
    };
    this.createFixedBuyNowButton = function () {
      var isFixedBuyNowButtonCreated = $('.btn-cart-fixed.btn-buy-now').length;
      if (!isFixedBuyNowButtonCreated) {
        var setFixedBuyNowButton = function () {
          var visibleBuyNowButton = $('.js-btn-main-buy-now:visible');
          var buttonContent = visibleBuyNowButton.html();
          $('.btn-cart-fixed.btn-buy-now')
            .css('display', visibleBuyNowButton.length ? 'flex' : 'none')
            .html(buttonContent);
        };

        var fixedBuyNowButton = $(
          '<div class="btn-cart-fixed btn-custom btn-buy-now"></div>',
        );
        $('.js-sticky-cart-button-container').append(fixedBuyNowButton);
        $('.btn-cart-fixed.btn-buy-now').on('click', function () {
          $('.js-btn-main-buy-now:visible').click();
        });
        setFixedBuyNowButton();

        // watch button status
        var watchBuyNowButtonStatus = new MutationObserver(function () {
          setFixedBuyNowButton();
        });

        watchBuyNowButtonStatus.observe(
          document.querySelector('.js-product-info'),
          { childList: true, subtree: true },
        );
      }
    };
  },
]);
