export const companySerialNoChecker = (serial) => {
  if (!serial) return false;
  if (!serial.match(/^\d{8}$/)) return false;
  const serialArray = serial.split('');
  const numArray = [1, 2, 1, 2, 1, 2, 4, 1];
  const result = serialArray.map(function (num, index) {
    return num * numArray[index];
  });

  let sum = 0;
  result.forEach(function (num) {
    sum += num
      .toString()
      .split('')
      .reduce(function (acc, curr) {
        return acc + Number(curr);
      }, 0);
  });
  return sum % 5 === 0 || ((sum + 1) % 5 === 0 && serial[6] === '7');
};
