app.service('logger', [
  function () {
    if (typeof Sentry !== 'undefined') {
      this.log = console.log;
      this.error = (...errors) => {
        try {
          if (!errors || errors.length === 0) return;

          if (typeof errors[0] === 'string') {
            const errorMsg = errors[0];

            console.log(errors);
            window.Sentry.withScope((scope) => {
              scope.setLevel(window.Sentry.Severity.Error);
              scope.setExtra('arguments', JSON.stringify(errors.slice(1)));

              window.Sentry.captureMessage(errorMsg);
            });
          } else {
            console.error(errors);
          }
        } catch (e) {
          console.error(e);
        }
      };
    } else {
      this.log = console.log;
      this.error = console.error;
    }
  },
]);
