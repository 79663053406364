import './services.digest-life';
import './services.image';
app.directive('carouselDisplay', [
  'imageService',
  '$window',
  '$compile',
  '$timeout',
  'digestLife',
  'slFeatureService',
  '$filter',
  function (
    imageService,
    $window,
    $compile,
    $timeout,
    digestLife,
    slFeatureService,
    $filter,
  ) {
    return {
      transclude: false,
      restrict: 'E',
      scope: {
        mobileMedia: '=',
        desktopMedia: '=',
        imageItem: '=',
        isPreview: '=',
      },
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.carousel-display.html'),
      link: function (scope, element) {
        scope.isMobile = $window.innerWidth < 768;
        function adjustCarouselHeight() {
          var target = element.find('.owl-carousel');
          var wrapperHeight = target.find('.owl-height').height();
          var owlItemHeight = target.find('.owl-item.active').height();
          if (wrapperHeight !== owlItemHeight) {
            target.trigger('refresh.owl.carousel');
          }
        }
        var options = {
          loop: true,
          items: 1,
          margin: 10,
          nav: false,
          lazyLoad: true,
          autoplay: true,
          autoHeight: true,
          autoHeightClass: 'owl-height',
          autoplayTimeout:
            parseFloat(
              (scope.imageItem && scope.imageItem.carousel.interval) || 5,
            ) * 1000,
          autoplayHoverPause: false,
          onLoadedLazy: function (event) {
            var target = element.find('.owl-carousel');
            window.resizeImages(event.target);
            if (target.hasClass('initializing')) {
              $(
                target.closest('.ImageItem-carouselContainer').children()[0],
              ).hide();
              target.removeClass('initializing');
              var wrapperWidth = Math.ceil(
                target.parents('.ImageItem-carouselContainer').width(),
              );
              var owlItemWidth = Math.ceil(target.find('.owl-item').width());
              // add this to solve banner image size enlarge and shrink issue
              if (wrapperWidth !== owlItemWidth)
                target.trigger('refresh.owl.carousel');
            }
            adjustCarouselHeight();
          },
          onInitialized: function () {
            window.resizeImages(element[0]);
            setTimeout(function () {
              element.find('.owl-carousel').trigger('refresh.owl.carousel');
            }, 1000);
          },
          onTranslate: function () {
            $('.owl-height').css('transition', 'height .5s ease-in-out');
          },
        };

        scope.switchMediaPlatform = function () {
          var isMobileValid =
            slFeatureService.hasFeature('image_widget_mobile') &&
            scope.mobileMedia.length;
          scope.media =
            scope.isMobile && isMobileValid
              ? scope.mobileMedia
              : scope.desktopMedia;
        };
        scope.switchMediaPlatform();

        scope.getImage = function (media) {
          return imageService.getMediaImageUrl(media);
        };
        scope.initCarousel = function () {
          element.find('.owl-carousel').owlCarousel(options);
        };
        scope.rebuildCarousel = function () {
          var owl = element.find('.owl-carousel');
          owl.owlCarousel('destroy');
          scope.switchMediaPlatform();
        };
        var afterDigest = _.throttle(function () {
          scope.initCarousel();
          digestLife.unregisterByScope(scope);
        }, 200);

        scope.$on('window.resize', function () {
          if ($window.innerWidth < 768 && !scope.isMobile) {
            scope.isMobile = true;
            scope.rebuildCarousel();
            scope.$apply();
          }
          if ($window.innerWidth >= 768 && scope.isMobile) {
            scope.isMobile = false;
            scope.rebuildCarousel();
            scope.$apply();
          }
        });
        // Hook on the scope
        digestLife.registerByScope(scope, afterDigest);

        function restartCarousel() {
          const carousel = $('carousel-display .owl-carousel');
          if (document.visibilityState === 'visible') {
            carousel.trigger('stop.owl.autoplay');
            carousel.trigger('next.owl.carousel');
          }
        }
        document.addEventListener('visibilitychange', restartCarousel);

        scope.getAlt = function (media) {
          if (!media) return '';
          return $filter('translateModel')(media.alt_translations);
        };

        scope.getLinkAndNewTab = function (media) {
          if (!media) return {};
          return {
            link: media.link,
            newTab: media.new_tab,
          };
        };

        scope.getImageSrcset = function (media, resizeAttrs) {
          const originSize = media.images.original.width;
          return imageService.getSrcsetWithResolutions(media, {
            ...resizeAttrs,
            originSize,
          });
        };
      },
    };
  },
]);
