export const USER_REMINDER_TYPE = {
  MEMBER_REFERRAL: 'MEMBER_REFERRAL',
  COUPON: 'COUPON',
};

export const USER_REMINDER_STORAGE_KEY = {
  MEMBER_REFERRAL_SL_MREF: 'user-reminder_member-referral_sl-mref',
  MEMBER_REFERRAL_CLICKED: 'user-reminder_member-referral_clicked',
  COUPON_EXPIRES: 'user-reminder_coupon_expires',
};
