app.directive('variationSelector', [
  '$filter',
  'userAgentService',
  function ($filter, userAgentService) {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        items: '=',
        quickCart: '=',
        product: '=',
        index: '@',
        onChange: '&',
        variantId: '@',
        containerClass: '@?',
      },
      templateUrl: require('../../../../../public/themes/v1/default/views/product.variation-selector.html'),
      link: function (scope, element) {
        var elementClass = scope.containerClass
          ? element.closest(scope.containerClass)[0]
          : element.find('.Variation-container')[0];
        var elementsPerRow = Math.floor(
          elementClass.getBoundingClientRect().width / 40,
        );
        var visibleRow = 3;

        if (scope.variantId) {
          var selectedVariation = scope.product.variations.find(
            function (variation) {
              return variation.key === scope.variantId;
            },
          );
          $filter('translateModel')(
            selectedVariation.fields_translations,
          ).forEach(function (optionName, index) {
            scope.model[index] = optionName;
          });
          scope.onChange();
          if (!userAgentService.isMobi()) {
            setTimeout(function () {
              $('.Variation-wrap').tooltip('hide');
            });
          }
        }

        scope.showMoreLimitCount = function () {
          return elementsPerRow * 2 - 1;
        };

        scope.numberOfElementInVisibleRow = elementsPerRow * visibleRow;

        scope.toggleShowMore = function () {
          scope.showMore = !scope.showMore;
        };

        var setOptionNames = function () {
          scope.nameArray = _.map(scope.options, function (item) {
            return $filter('translateModel')(item.name_translations);
          });
          scope.showMore =
            scope.nameArray.indexOf(scope.model[scope.index]) >
            scope.showMoreLimitCount();
        };

        var setOptions = function () {
          scope.options = scope.product.digest_variations[scope.index].map(
            function (variation) {
              return _.find(scope.product.variant_options, function (option) {
                return (
                  $filter('translateModel')(option.name_translations) ===
                  variation
                );
              });
            },
          );
          scope.displayShowMoreButton =
            scope.options.length > elementsPerRow * 2;
        };

        scope.$watch(
          'product.digest_variations',
          function () {
            setOptions();
            setOptionNames();
          },
          true,
        );

        scope.selectVariation = function (selectedIndex) {
          scope.model[scope.index] = scope.nameArray[selectedIndex];
          scope.onChange();
          if (!userAgentService.isMobi()) {
            setTimeout(function () {
              $('.Variation-wrap').tooltip('hide');
            });
          }
        };

        if (userAgentService.isMobi()) {
          $('.Variation-wrap').tooltip('disable');
        } else {
          setTimeout(function () {
            $('.Variation-wrap').tooltip();
          });
        }
      },
    };
  },
]);
