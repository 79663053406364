app.service('memberPointsService', [
  '$http',
  function ($http) {
    var limit = 24;

    this.getMemberPoints = function (page) {
      return $http({
        url: '/api/member_points',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          page: page,
          limit: limit,
        },
      });
    };
    this.getFulfillmentSummary = function (limit) {
      return $http({
        url: '/api/member_point_fulfillments/summary',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          limit: limit,
        },
      });
    };
    this.getMemberPointRules = function ({ rule_type } = {}) {
      return $http({
        url: '/api/member_point_rules',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: { rule_type },
      });
    };
  },
]);
