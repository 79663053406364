app.directive('promotionTermModal', [
  '$rootScope',
  '$uibModal',
  'promotion',
  '$filter',
  '$timeout',
  function ($rootScope, $uibModal, promotion, $filter, $timeout) {
    return {
      restrict: 'E',
      scope: {},
      template:
        '<div class="show-term-button" ng-click="showTermModal()">{{ buttonText }}</div>',
      link: function (scope) {
        scope.buttonText = $filter('translate')('promotions.page.term.title');

        function getPromotionTerm() {
          if (promotion.term_translations) {
            return _.escape(
              $filter('translateModel')(promotion.term_translations),
            );
          }
        }

        scope.showTermModal = function () {
          $rootScope.currentModal = $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.promotion-term.html'),
            controller: [
              '$scope',
              '$uibModalInstance',
              function ($scope, $uibModalInstance) {
                var termText = getPromotionTerm();
                $scope.termText = termText.replace(/\n/g, '<br>');
                $scope.confirm = function () {
                  $uibModalInstance.close();
                };
                $timeout(function () {
                  $('.promotion-term-dialog .scroll-area').overlayScrollbars({
                    overflowBehavior: { x: 'hidden' },
                  });

                  $('.promotion-term-modal-backdrop').one('click', function () {
                    $uibModalInstance.dismiss('cancel');
                  });
                });
              },
            ],
            windowClass: 'promotion-term-modal-window',
            backdropClass: 'promotion-term-modal-backdrop',
          });
          $rootScope.currentModal.opened = $rootScope.currentModal.opened.then(
            function () {
              $rootScope.$emit('modal.open');
            },
          );
        };
      },
    };
  },
]);
