app.service('imageService', [
  'imageServiceEndpoint',
  'slFeatureService',
  'mainConfig',
  'RESOLUTION_LIST',
  'NEW_RESOLUTION_LIST',
  function (
    imageServiceEndpoint,
    slFeatureService,
    mainConfig,
    RESOLUTION_LIST,
    NEW_RESOLUTION_LIST,
  ) {
    var webpHandler = function (sourceFormat) {
      return mainConfig.webpSupported
        ? 'webp?source_format=' + sourceFormat
        : sourceFormat + '?';
    };

    return {
      getExternalImageUrl: function (url, option) {
        if (option === undefined) {
          option = {};
        }
        if (option.size === undefined) {
          option.size = '1000x';
        }

        if (
          slFeatureService.hasFeature('disable_image_service') ||
          !/http/.test(url)
        ) {
          return url;
        } else {
          return (
            imageServiceEndpoint +
            mainConfig.merchantId +
            '/external-' +
            md5(url) +
            '/' +
            option.size +
            '?image_url=' +
            encodeURIComponent(url)
          );
        }
      },
      getMediaImageUrl: function (media, option) {
        if (option === undefined) {
          option = {};
        }
        if (option.size === undefined) {
          option.size = '200x';
        }

        if (slFeatureService.hasFeature('disable_image_service')) {
          return media && media.images['original'].url;
        } else {
          if (!media) return;
          var id = media._id;
          //url.match(/(image|second)_clips\/([0-9A-z]+)\//)[2];
          var regex = new RegExp(/\.([A-z]+)\?/);
          var result = regex.exec(media.images.original.url);
          var sourceFormat = result && result[1];

          if (/png|jpg|jpeg|gif/i.test(sourceFormat)) {
            return (
              imageServiceEndpoint +
              mainConfig.merchantId +
              '/' +
              id +
              '/' +
              option.size +
              '.' +
              webpHandler(sourceFormat)
            );
          } else {
            return media.images.original.url;
          }
        }
      },
      getLargeImage: function (imgUrl) {
        if (!imgUrl) return '';
        if (!mainConfig.merchantData.is_image_service_enabled) return imgUrl;

        var fileName = _.last(imgUrl.split('/'));
        var size = fileName.split('.')[0];
        // This regex will differentiate between image url of imageService and image url of S3
        var regex = /\.(webp|png|jpg|jpeg|gif)\?/i;
        var result = regex.test(fileName);
        if (result) imgUrl = imgUrl.replace(size, '800x');
        return imgUrl;
      },
      generateResolutions(resizeAttrs) {
        if (!resizeAttrs) {
          return NEW_RESOLUTION_LIST;
        }
        const originSize = resizeAttrs.originSize || 0;
        const mobileSize = resizeAttrs.mobileSize || 0;
        const desktopSize = resizeAttrs.desktopSize || 0;

        const range = [mobileSize * 3, desktopSize * 2];
        let maxSize = Math.max(...range);
        if (originSize > 0 && maxSize < originSize) {
          maxSize = originSize;
        }

        const index = NEW_RESOLUTION_LIST.findIndex((size) => size >= maxSize);
        if (index < 0 || !maxSize) {
          return NEW_RESOLUTION_LIST;
        } else {
          return NEW_RESOLUTION_LIST.slice(0, index + 1);
        }
      },
      getSrcsetWithResolutions(media, resizeAttrs = {}) {
        if (!media) return '';

        const widths = resizeAttrs.widths || '';
        let sizes = '';
        if (!widths || typeof widths !== 'string' || widths === 'auto') {
          sizes = this.generateResolutions(resizeAttrs);
        } else {
          sizes = widths.split(',').map((s) => parseInt(s));
        }

        return this.getSrcset(media, sizes);
      },
      getSrcset: function (media, sizes = RESOLUTION_LIST) {
        return sizes
          .map(
            (size) =>
              `${this.getMediaImageUrl(media, { size: `${size}x` })} ${size}w`,
          )
          .join(', ');
      },
    };
  },
]);
