import './services.tracker';
import './services.slpixel';
import './services.location';
app.controller('ProductsSearchController', [
  '$window',
  '$scope',
  'mainConfig',
  'locationService',
  'slPixelService',
  'trackerService',
  function (
    $window,
    $scope,
    mainConfig,
    locationService,
    slPixelService,
    trackerService,
  ) {
    $scope.search = function (searchQuery, $event) {
      $event.preventDefault();

      var appendUrl = '';
      if (searchQuery != undefined) {
        appendUrl +=
          '/products?query=' + $window.encodeURIComponent(searchQuery);

        if (locationService.getQueryParams().limit) {
          appendUrl += '&limit=' + locationService.getQueryParams().limit;
        }

        try {
          //facebook tracking pixel Standard Event: search
          fbq('track', 'Search', {
            search_string: searchQuery,
          });
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }

        slPixelService.hdPageView('searchResult', { keyword: searchQuery });
        trackerService.track({
          type: trackerService.generalEventType.SEARCH,
          data: {
            query: searchQuery,
          },
        });
        $window.location.href = appendUrl + mainConfig.appendToUrl;
      }
    };

    $scope.clearSearch = function () {
      $scope.searchQuery = null;
    };
  },
]);
