import './services.cart';
import './services.tracker';
import './services.pnotify';
import './services.product';
app.directive('redeemGiftItem', [
  'productService',
  'pnotifyService',
  'trackerService',
  'slFeatureService',
  'cartService',
  '$window',
  '$rootScope',
  '$filter',
  '$uibModal',
  function (
    productService,
    pnotifyService,
    trackerService,
    slFeatureService,
    cartService,
    $window,
    $rootScope,
    $filter,
    $uibModal,
  ) {
    return {
      restrict: 'E',
      scope: {
        redeemGiftId: '@',
        redeemPoint: '=',
        redeemGiftHasVariations: '=',
      },
      link: function (scope, element) {
        let isVariationPickerOpened = false;
        scope.$on('redeemPointUpdate', function (e, remainingMemberPoint) {
          scope.notEnoughMemberPoint =
            remainingMemberPoint - scope.redeemPoint < 0;
        });

        element.on('click', '.js-btn-add-to-promotion-cart', function (e) {
          e.preventDefault();
          if (!$rootScope.isUserLoggedIn) {
            return ($window.location.href = '/users/sign_in');
          }
          if (!$(e.target).hasClass('sold-out-item')) {
            scope.addItemToCart();
          }
        });

        const afterAddToCart = ({ data, quantity, variation = null }) => {
          const redeemGift = data.items.find((item) => {
            return item.product_id === scope.redeemGiftId;
          });
          trackerService.track({
            type: trackerService.generalEventType.ADD_TO_CART,
            data: {
              items: [
                {
                  product: redeemGift.product,
                  value: 0,
                  quantity,
                  variationSelected: variation,
                },
              ],
              eventId: data.event_id,
            },
          });
        };

        const addToCartError = () => {
          pnotifyService.notify(
            $filter('translate')('products.quick_cart.low_stock_hint'),
            {
              customClass: 'error',
              icon: 'fa fa-exclamation-triangle',
            },
          );
        };

        const hasProductStock = (redeemGiftStock, quantity) => {
          const result = cartService.checkStockResult(
            quantity,
            redeemGiftStock,
          );
          return result.notEnoughStockQty < 0;
        };

        const hasStock = (redeemGiftStock, quantity) =>
          redeemGiftStock &&
          (redeemGiftStock.unlimited_quantity ||
            redeemGiftStock.cart_quantity + quantity <=
              redeemGiftStock.quantity);

        const addItem = ({ quantity, variation }) => {
          const cartItemData = {
            quantity,
            type: 'redeem_gift',
            ...(variation ? { variation } : {}),
          };
          cartService
            .addItem(scope.redeemGiftId, cartItemData, undefined, {
              skip_calculate_order: false,
            })
            .then(
              (data) =>
                afterAddToCart({
                  data,
                  quantity,
                  ...(variation ? { variation } : {}),
                }),
              addToCartError,
            );
        };

        scope.addItemToCart = async () => {
          if (scope.notEnoughMemberPoint) {
            return pnotifyService.notify(
              $filter('translate')('products.quick_cart.insufficient_point'),
              { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
            );
          }

          if (scope.redeemGiftHasVariations) {
            if (isVariationPickerOpened) {
              return;
            }
            isVariationPickerOpened = true;
            const resp = await productService.getById(scope.redeemGiftId);
            const gift = resp.data.data;
            const newScope = scope.$new(true);
            newScope.product = gift;
            newScope.cart = null;
            newScope.isRedeemGift = true;
            newScope.redeemPoint = scope.redeemPoint;
            newScope.product.hide_price = false;
            const modal = $uibModal.open({
              templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.product.quick_cart.html'),
              controller: 'productVariationPickerController',
              scope: newScope,
              windowClass:
                'QuickCart-modal product-set-quick-cart-modal product-variation-picker',
            });
            modal.opened.then(() =>
              $rootScope.$emit('modal.open', {
                modalType: 'QUICK_CART_PRODUCT_SET',
              }),
            );
            modal.result.then(({ quantity, variation }) => {
              productService
                .checkStock(scope.redeemGiftId, variation.key)
                .then((data) => {
                  if (hasProductStock(data.data, quantity)) {
                    addItem({ quantity, variation });
                  } else {
                    addToCartError();
                  }
                });
            });
            modal.closed.then(() => {
              isVariationPickerOpened = false;
            });
            return;
          } else {
            productService.checkStock(scope.redeemGiftId).then((data) => {
              const hasSufficientStock = slFeatureService.hasFeature(
                'addon_gift_as_product',
              )
                ? hasProductStock(data.data, 1)
                : hasStock(data.data, 1);

              if (hasSufficientStock) {
                addItem({ quantity: 1 });
              } else {
                addToCartError();
              }
            });
          }
        };
      },
    };
  },
]);
