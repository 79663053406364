(function () {
  app.directive('productsSort', [
    '$location',
    function ($location) {
      return {
        restrict: 'A',
        link: function (scope) {
          var currentQueryParams = $location.search();
          var sortOptions = [
            'created_at_desc',
            'created_at_asc',
            'price_desc',
            'price_asc',
            'quantity_sold_desc',
          ];

          var appendNewQueryToUrl = function () {
            Object.assign(currentQueryParams, {
              sort_by: scope.sort_by,
              order_by: scope.order_by,
            });
            $location.search(currentQueryParams);
            window.location.href = $location.absUrl();
          };

          if (currentQueryParams.sort_by === 'created_at') {
            if (currentQueryParams.order_by === 'desc') {
              scope.sortQuery = sortOptions[0];
            } else if (currentQueryParams.order_by === 'asc') {
              scope.sortQuery = sortOptions[1];
            }
          } else if (currentQueryParams.sort_by === 'lowest_price') {
            if (currentQueryParams.order_by === 'desc') {
              scope.sortQuery = sortOptions[2];
            } else if (currentQueryParams.order_by === 'asc') {
              scope.sortQuery = sortOptions[3];
            }
          } else if (currentQueryParams.sort_by === 'quantity_sold') {
            if (currentQueryParams.order_by === 'desc') {
              scope.sortQuery = sortOptions[4];
            }
          }

          scope.$watch('sortQuery', function (newValue, oldValue) {
            if (oldValue !== newValue) {
              switch (newValue) {
                case 'created_at_asc':
                  scope.sort_by = 'created_at';
                  scope.order_by = 'asc';
                  break;
                case 'created_at_desc':
                  scope.sort_by = 'created_at';
                  scope.order_by = 'desc';
                  break;
                case 'price_desc':
                  scope.sort_by = 'lowest_price';
                  scope.order_by = 'desc';
                  break;
                case 'price_asc':
                  scope.sort_by = 'lowest_price';
                  scope.order_by = 'asc';
                  break;
                case 'quantity_sold_desc':
                  scope.sort_by = 'quantity_sold';
                  scope.order_by = 'desc';
                  break;
              }
              appendNewQueryToUrl();
            }
          });
        },
      };
    },
  ]);
})();
