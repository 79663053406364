app.directive('checkStoreStockButton', [
  '$uibModal',
  function ($uibModal) {
    return {
      restrict: 'E',
      templateUrl:
        '/themes/v1/default/views/templates.check-store-stock-button.html',
      link: function (scope) {
        scope.onClickCheckStocks = function () {
          const newScope = scope.$new();
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/templates.check-store-stock-modal.html'),
            windowClass: 'check-store-stock-modal',
            controller: 'CheckStoreStockModal',
            scope: newScope,
            keyboard: false,
          });
        };
      },
    };
  },
]);
