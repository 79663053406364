app.directive('basicPopover', [
  '$rootScope',
  'staticImageHost',
  '$sce',
  '$filter',
  'mainConfig',
  function ($rootScope, staticImageHost, $sce, $filter, mainConfig) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.basic-popover.html'),
      link: function ($scope) {
        function isElementInViewport(el) {
          if (typeof jQuery === 'function' && el instanceof jQuery) {
            el = el[0];
          }
          var rect = el.getBoundingClientRect();
          return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
              (window.innerHeight ||
                document.documentElement
                  .clientHeight) /* or $(window).height() */ &&
            rect.right <=
              (window.innerWidth ||
                document.documentElement.clientWidth) /* or $(window).width() */
          );
        }
        function scrollToShowPopover() {
          if (mainConfig.merchantData.current_theme_key !== 'doris_bien')
            return;
          if (!isElementInViewport($('basic-popover'))) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }
        function openSideCart() {
          $scope.showPopover = false;
          $('.sl-cart-toggle').click();
        }
        function closePopover() {
          $scope.showPopover = false;
        }
        $rootScope.$on('cart.overLimit', function (event, payload) {
          const limitNumber = 100;
          $scope.showPopover = true;
          $scope.popoverConfig = {
            title: $filter('translate')('product.add_to_cart_fail'),
            btnText: $filter('translate')('cart.clean'),
            iconSrc: `//${staticImageHost}/assets/cart-limit-popover-icon.svg`,
            description: $sce.trustAsHtml(
              $filter('translate')('cart.over_limit', {
                limit_number: limitNumber,
              }),
            ),
            closeBtnEvent: closePopover,
            btnEvent: openSideCart,
          };
          if (payload?.login) {
            $scope.popoverConfig.description = $sce.trustAsHtml(
              $filter('translate')('cart.over_limit.login', {
                limit_number: limitNumber,
              }),
            );
          }
          scrollToShowPopover();
        });
        $rootScope.$broadcast('basic-popover.loaded');
      },
    };
  },
]);
