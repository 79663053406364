import './services.feature';
import './services.image';
app.directive('productListVariationSelector', [
  'imageService',
  'mainConfig',
  'featureService',
  function (imageService, mainConfig, featureService) {
    return {
      restrict: 'E',
      scope: {
        variantProductImages: '=',
        productId: '@',
        productUrl: '@',
        openLinkInNewTab: '=',
        showVariationType: '@',
        isPromotionProduct: '=',
        queryProductImageWidth: '=',
      },
      templateUrl: require('../../../../../public/themes/shared/product/templates.product_list_variation_selector.html'),
      link: function (scope, element) {
        function checkMediaSize() {
          scope.isMobile = window.matchMedia('(max-width: 480px)').matches;
          scope.isTablet = window.matchMedia(
            '(min-width: 481px) and (max-width: 991px)',
          ).matches;
        }
        checkMediaSize();

        //  If the variant image size is L, then the expand button needs to be placed, after the last variant image as requested by the UI/UX Designer.
        scope.isVariantImageSizeSupported =
          featureService.hasFeature('shop_builder_plp') &&
          mainConfig.merchantData.current_theme_key === 'ultra_chic' &&
          mainConfig.themeSettings &&
          mainConfig.themeSettings.variation_image_size === 'lg';

        scope.variantCount =
          scope.variantProductImages[
            'variation_set_' + scope.showVariationType + '_total'
          ];
        scope.showVariantProductImages =
          scope.variantProductImages[
            'variation_set_' + scope.showVariationType
          ];

        function getUrlParam(variantOption) {
          if (variantOption) {
            return location.search
              ? location.search + '&variant_id=' + variantOption.key
              : '?variant_id=' + variantOption.key;
          } else {
            return location.search;
          }
        }

        var skipClickTarget =
          '.product-list-variant-selector-toggle > span, .product-list-variant-selector-list';
        element.on('click', function (e) {
          if ($(e.target).is(skipClickTarget)) {
            e.preventDefault();
          }
        });

        scope.state = {
          expand: false,
          maxVariantsCount: 6,
          showLeftMask: false,
          showRightMask: false,
          hoveredOption: {},
        };

        scope.toggleVariantsExpand = function () {
          scope.state.expand = !scope.state.expand;
          if (scope.state.expand) {
            scope.state.maxVariantsCount *= 2;
          } else {
            scope.state.maxVariantsCount /= 2;
          }
        };

        var productElement = $('[product-id=' + scope.productId + ']');
        var productQuerySelectors = [
          '[product-id=' + scope.productId + '] > a',
          'a[product-id=' + scope.productId + ']',
        ];

        var currentThemeKey = mainConfig.merchantData.current_theme_key;
        var productHrefElement = $(productQuerySelectors.join(', '));
        if (currentThemeKey === 'swanky') {
          productElement = productElement.filter(function (idx) {
            return (
              $(productElement[idx]).next().is(element) ||
              $(productElement[idx]).has(element).length
            );
          });
          var anchorProductElement = productElement.filter(function (idx) {
            return $(productElement[idx]).is('a');
          });
          productHrefElement = anchorProductElement.length
            ? anchorProductElement
            : productElement.find('> a');
        } else {
          productElement = productElement.has(element);
          productHrefElement = productHrefElement.has(element);
        }

        var imagesQuerySelectors = [
          '.js-image-boxify-image', // quick cart image selector
          '.js-image-boxify-image .js-boxify-image', // product image selector
          '.boxify-image', // promotion product image selector
        ];

        var imagesQuery = productElement.find(imagesQuerySelectors.join(','));

        function updateVariantsLengthByMedia() {
          if (scope.isMobile) {
            scope.state.maxVariantsLength = 116;
            scope.state.maxVariantsCount = scope.state.expand ? 8 : 4;
          } else {
            scope.state.maxVariantsLength = scope.isTablet ? 174 : 204;
            scope.state.maxVariantsCount = scope.state.expand ? 12 : 6;
          }
        }

        function updateVariantsLengthByImage() {
          var productImageWidth = imagesQuery[0].clientWidth;
          if (currentThemeKey === 'hype') {
            productImageWidth -= 20;
          }
          var variationImageWidth = scope.isMobile || scope.isTablet ? 29 : 24;
          var rowVariantsCount = Math.min(
            parseInt(productImageWidth / variationImageWidth),
            6,
          );
          scope.state.maxVariantsCount = scope.state.expand
            ? 2 * rowVariantsCount
            : rowVariantsCount;
          scope.state.maxVariantsLength =
            variationImageWidth * rowVariantsCount;
        }

        function updateVariantsLength() {
          if (scope.queryProductImageWidth) {
            updateVariantsLengthByImage();
          } else {
            updateVariantsLengthByMedia();
          }
        }
        updateVariantsLength();

        scope.getShowMoreAmount = function () {
          return (
            scope.variantCount -
            (scope.isMobile || scope.isTablet
              ? scope.showVariantProductImages.length
              : scope.state.maxVariantsCount)
          );
        };

        scope.getMaxWidth = function () {
          return 'min(100%, ' + scope.state.maxVariantsLength + 'px)';
        };

        function checkShouldShowRightMask() {
          scope.state.showRightMask =
            (scope.isMobile || scope.isTablet) &&
            scope.showVariantProductImages.length >
              scope.state.maxVariantsCount;
        }
        checkShouldShowRightMask();

        $(window).on(
          'resize',
          _.debounce(function () {
            checkMediaSize();
            checkShouldShowRightMask();
            updateVariantsLength();
          }, 100),
        );

        function getOriginSrcset() {
          return imageTagElement.find('img').first().attr('srcset');
        }
        const PRODUCT_IMAGE_WRAPPER = '.boxify-image-wrap';
        const imageTagElement = productElement.find(PRODUCT_IMAGE_WRAPPER);
        let originalSrcset = getOriginSrcset();

        var originalImageUrl = imagesQuery
          .first()
          .css('background-image')
          .replace('url(', '')
          .replace(')', '')
          .replace(/"/gi, '');
        var imageSize = imagesQuery.first().attr('data-size') || '400x';

        var quickCartBtnElement = productElement.find(
          scope.isPromotionProduct
            ? '.btn-add-to-promotion-cart'
            : '.btn-add-to-cart',
        );
        var imageElement = productElement.find(imagesQuery);
        var showMoreElement = element.find(
          '.product-list-variant-selector-show-more',
        );
        showMoreElement.attr('href', scope.productUrl);

        scope.updatePreviewProduct = function (e, variantOption) {
          // originalSrcset may be undefined because lazysizes.js sometimes execute later than this directive's link function,
          // because we need the image of the main product,
          // so assign the value only at user's first time hovering over product-list-variation-selector's item
          originalSrcset = !originalSrcset ? getOriginSrcset() : originalSrcset;
          var imageUrl =
            variantOption && variantOption.variation_media
              ? imageService.getMediaImageUrl(variantOption.variation_media, {
                  size: imageSize,
                })
              : originalImageUrl;

          // Update selected variation image
          // Update srcset if image element is img tag
          if (imageTagElement.length) {
            const srcset =
              imageUrl !== 'none'
                ? imageService.getSrcsetWithResolutions(
                    variantOption.variation_media,
                    { originSize: 900 },
                  )
                : originalSrcset;
            imageTagElement.find('img').attr('srcset', srcset);
          } else {
            imageElement.css('background-image', `url(${imageUrl})`);
          }

          var newProductUrl =
            scope.productUrl.split('?')[0] + getUrlParam(variantOption);
          productHrefElement.attr('href', newProductUrl);
          showMoreElement.attr('href', newProductUrl);
          quickCartBtnElement.attr('data-variant-id', variantOption.key);
          scope.state.hoveredOption = variantOption ? variantOption : {};
          var wishlistElement = productElement.find(
            'sl-list-page-wishlist-button',
          );
          wishlistElement.attr('data-variant-id', variantOption.key);

          e.preventDefault();
        };

        scope.getVariantImageUrl = function (url) {
          return imageService.getMediaImageUrl(url, { size: '30x30' });
        };

        var scrollableQuery = element.find(
          '.product-list-variant-selector-scrollable',
        );
        scrollableQuery.scroll(
          _.debounce(function ($event) {
            if (scope.isMobile || scope.isTablet) {
              scope.state.showLeftMask = $event.target.scrollLeft > 0;
              scope.state.showRightMask =
                $event.target.scrollLeft + $event.target.offsetWidth <
                $event.target.scrollWidth;
              scope.$apply();
            }
          }, 25),
        );

        $(
          '[product-id=' +
            scope.productId +
            '] .product-list-variant-selector-right-mask',
        ).click(function ($event) {
          if (scope.isMobile || scope.isTablet) {
            scrollableQuery[0].scrollBy({
              top: 0,
              left: scope.state.maxVariantsLength / 2,
              behavior: 'smooth',
            });
            $event.preventDefault();
          }
        });
        $(
          '[product-id=' +
            scope.productId +
            '] .product-list-variant-selector-left-mask',
        ).click(function ($event) {
          if (scope.isMobile || scope.isTablet) {
            scrollableQuery[0].scrollBy({
              top: 0,
              left: -scope.state.maxVariantsLength / 2,
              behavior: 'smooth',
            });
            $event.preventDefault();
          }
        });
      },
    };
  },
]);
