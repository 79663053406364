const STORAGE_EXPIRES_KEY = 'product_set_cache_expires';
const STORAGE_BASKETS_KEY = 'product_set_cache_baskets';

// NOTE: don't try to maintain storage data in instance variable
// since it may be changed in other tabs
app.service('productSetCacheService', function () {
  const initStorageData = () => {
    setBaskets({});
    updateExpires();
  };

  this.getBaskets = () => {
    const expires = Number(localStorage.getItem(STORAGE_EXPIRES_KEY));
    if (!expires || dayjs().isAfter(dayjs(expires))) {
      initStorageData();
    }

    return JSON.parse(localStorage.getItem(STORAGE_BASKETS_KEY));
  };

  const setBaskets = (baskets) => {
    try {
      localStorage.setItem(STORAGE_BASKETS_KEY, JSON.stringify(baskets));
    } catch (error) {
      // if exceed localStorage max size (QUOTA_EXCEEDED_ERR)
      // clear all existed data and setItem again
      initStorageData();
      localStorage.setItem(STORAGE_BASKETS_KEY, JSON.stringify(baskets));
    }
  };

  const updateExpires = () => {
    const expires = dayjs().add(2, 'weeks').valueOf();
    localStorage.setItem(STORAGE_EXPIRES_KEY, expires);
  };

  this.getProductCache = (_id, childProducts) => {
    const baskets = this.getBaskets();
    if (!baskets[_id]) {
      return [];
    }

    const result = [];
    for (let item of baskets[_id]) {
      const targetProduct = childProducts.find(
        (product) => product._id === item.productId,
      );
      if (!targetProduct) {
        break;
      }

      // variation changed
      const targetVariation = targetProduct.variations.find(
        (variation) => variation.key === item.variationKey,
      );
      if (!targetVariation) {
        break;
      }

      const isOrderable =
        targetProduct.out_of_stock_orderable ||
        targetProduct.unlimited_quantity ||
        targetVariation.quantity >= item.quantity;
      if (!isOrderable) {
        break;
      }

      result.push({
        ...targetProduct,
        variationSelected: targetVariation,
        quantity: item.quantity,
      });
    }

    // check is early breaked or not
    if (result.length !== baskets[_id].length) {
      this.clearProductCache(_id);
      return [];
    }

    updateExpires();
    return result;
  };

  this.updateProductCache = (_id, basketCartItems) => {
    const result = [];
    basketCartItems.forEach((item) => {
      if (_.isEmpty(item.variations)) {
        return;
      }

      result.push({
        productId: item._id,
        variationKey: item.variationSelected.key,
        quantity: item.quantity,
      });
    });

    const baskets = this.getBaskets();
    baskets[_id] = result;
    setBaskets(baskets);
    updateExpires();
  };

  this.clearProductCache = (_id) => {
    const baskets = this.getBaskets();
    delete baskets[_id];

    setBaskets(baskets);
  };
});
