app.service('execCommandService', [
  'userAgentService',
  function (userAgentService) {
    return {
      copyToClipboard: function (copyText) {
        var body = document.body;
        var copyElement = document.createElement('textarea');
        copyElement.style.display = 'fixed';
        copyElement.style['z-index'] = '-999';
        copyElement.value = copyText;
        body.appendChild(copyElement);

        if (userAgentService.isiOS()) {
          var range = document.createRange();
          var selection = window.getSelection();
          range.selectNodeContents(copyElement);
          selection.removeAllRanges();
          selection.addRange(range);
          copyElement.setSelectionRange(0, copyText.length);
        } else {
          copyElement.select();
        }

        document.execCommand('copy');
        body.removeChild(copyElement);
      },
    };
  },
]);
