import './services.local-datetime';
app.controller('PromotionPageController', [
  '$scope',
  '$rootScope',
  '$filter',
  '$uibModal',
  '$timeout',
  'mainConfig',
  'localDatetimeServices',
  'promotion',
  function (
    $scope,
    $rootScope,
    $filter,
    $uibModal,
    $timeout,
    mainConfig,
    localDatetimeServices,
    promotion,
  ) {
    var dateStringFormat = 'YYYY/MM/DD HH:mm';
    var specificDateStringFormat = 'HH:mm DD/MM/YYYY';
    $scope.promotion = promotion;
    $scope.getFormattedDateString = function (i18nKey, date) {
      if (!date) return '';

      return $filter('translate')(i18nKey, {
        date: dayjs(date).format(
          mainConfig.localeData.loadedLanguage.code === 'vi'
            ? specificDateStringFormat
            : dateStringFormat,
        ),
      });
    };

    $scope.isBundleGroupDiscountType = [
      'bundle_group',
      'bundle_group_gift',
      'bundle_group_amount',
      'bundle_group_percentage',
    ].includes(promotion.discount_type);

    $scope.getPromotionSummary = function () {
      const loadedLanguage = mainConfig.localeData.loadedLanguage.code;
      return promotion.summary[loadedLanguage] || '';
    };

    $scope.showTermModal = function (termText) {
      $rootScope.currentModal = $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.promotion-term.html'),
        controller: [
          '$scope',
          '$filter',
          '$uibModalInstance',
          function ($scope, $filter, $uibModalInstance) {
            if (!termText)
              termText = $filter('translate')('promotions.page.term.default');
            $scope.termText = termText.replace(/\n/g, '<br>');

            $scope.confirm = function () {
              $uibModalInstance.close();
            };

            $timeout(function () {
              $('.promotion-term-dialog .scroll-area').overlayScrollbars({
                overflowBehavior: { x: 'hidden' },
              });

              $('.promotion-term-modal-backdrop').one('click', function () {
                $uibModalInstance.dismiss('cancel');
              });
            });
          },
        ],
        windowClass: 'promotion-term-modal-window',
        backdropClass: 'promotion-term-modal-backdrop',
      });
      $rootScope.currentModal.opened = $rootScope.currentModal.opened.then(
        function () {
          $rootScope.$emit('modal.open');
        },
      );
    };

    $timeout(function () {
      var $dateDOM = $('.PromotionPage-container .local-datetime');
      var offset = new Date().getTimezoneOffset() / -60;
      var title = $filter('translate')(
        'promotions.page.display_local_timezone',
        {
          offset: offset < 0 ? offset : '+' + Math.abs(offset),
        },
      );

      localDatetimeServices.mountTooltip($dateDOM, title);
    });

    $('.js-show-term-button').on('click', function () {
      $scope.showTermModal(this.dataset.promotionTerm);
    });
  },
]);
