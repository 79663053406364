import './services.cookie-consent';
app.controller('CookieConsentPopupController', [
  '$scope',
  'cookieConsentService',
  '$filter',
  function ($scope, cookieConsentService, $filter) {
    /**
     * Save cookie consent preferences
     * @param {string[]} preferences
     */
    const saveCookieConsentPreferences = (preferences) => {
      if ($scope.$$state.loading || !Array.isArray(preferences)) {
        return;
      }

      $scope.$$state.loading = true;

      // Extract selected cookie type keys
      const newPreferences = preferences.filter((c) => c.on).map((c) => c.key);

      // Try to save user preference
      // NOTE: It will delete all cookie
      const savePreferencesSuccess = cookieConsentService.savePreferences(
        newPreferences,
        newPreferences.length !== $scope.$$state.cookieConsentSettings.length,
      );

      // Reload page if save success
      if (savePreferencesSuccess) {
        window.location.reload();
      } else {
        $scope.$$state.loading = false;
      }
    };

    /**
     *  Currently there is no translation for other language
     */
    var t = $filter('translate');

    $scope.$$state = {
      showPopup: false,
      showDetailSettingsPanel: false,
      loading: false,
      cookieConsentSettings: [
        {
          key: 'necessary',
          title: t('cookie_consent_popup.cookie_names.necessary'),
          description: t('cookie_consent_popup.cookie_descriptions.necessary'),
          on: true,
          disabled: true,
        },
        {
          key: 'statistical',
          title: t('cookie_consent_popup.cookie_names.statistical'),
          description: t(
            'cookie_consent_popup.cookie_descriptions.statistical',
          ),
          on: true,
          disabled: false,
        },
        {
          key: 'marketing',
          title: t('cookie_consent_popup.cookie_names.marketing'),
          description: t('cookie_consent_popup.cookie_descriptions.marketing'),
          on: true,
          disabled: false,
        },
        {
          key: 'functionality',
          title: t('cookie_consent_popup.cookie_names.functionality'),
          description: t(
            'cookie_consent_popup.cookie_descriptions.functionality',
          ),
          on: true,
          disabled: false,
        },
      ],
    };

    /**
     *  Currently there is no translation for other language
     */
    $scope.$$wordings = [
      {
        name: 'consent_title',
        value: t('cookie_consent_popup.claim.heading'),
      },
      {
        name: 'consent_desc',
        value: t('cookie_consent_popup.claim.description'),
      },
      {
        name: 'read_more',
        value: t('cookie_consent_popup.claim.read_more'),
      },
      {
        name: 'accept',
        value: t('cookie_consent_popup.buttons.accept'),
      },
      {
        name: 'save_preferences',
        value: t('cookie_consent_popup.buttons.save_preferences'),
      },
      {
        name: 'settings',
        value: t('cookie_consent_popup.buttons.settings'),
      },
    ];

    $scope.$$links = [
      {
        name: 'read_more',
        value: '/about/terms',
      },
    ];

    $scope.$$clickables = [
      {
        name: 'settings',
        value: () => {
          $scope.$$state.showDetailSettingsPanel = true;
        },
      },
      {
        name: 'accept',
        value: () => {
          saveCookieConsentPreferences($scope.$$state.cookieConsentSettings);
        },
      },
      {
        name: 'save_preferences',
        value: () => {
          saveCookieConsentPreferences($scope.$$state.cookieConsentSettings);
        },
      },
      {
        name: 'discard',
        value: () => {
          $scope.$$state.showDetailSettingsPanel = false;
        },
      },
    ];

    /**
     * Get component by name
     * @param {Array} components
     * @param {string} name
     * @param {*} fallback
     * @returns {*}
     */
    $scope.$$getComponent = (components, name, fallback) => {
      const matched = components.find((c) => c.name === name);
      if (matched) {
        return matched.value;
      } else {
        return fallback;
      }
    };

    /**
     * Get translation
     * @param {string} name
     * @returns {string}
     */
    $scope.word = (name) => {
      return $scope.$$getComponent($scope.$$wordings, name, '');
    };

    /**
     * Trigger click event
     * @param {string} name
     */
    $scope.click = (name) => {
      const click = $scope.$$getComponent($scope.$$clickables, name, () => {
        console.error('clickable not exist', name);
      });
      if (typeof click === 'function') {
        click();
      } else {
        console.error('clickable is not a function', click);
      }
    };

    /**
     * Get link
     * @param {string} name
     * @returns {string}
     */
    $scope.alink = (name) => {
      return $scope.$$getComponent($scope.$$links, name, '/');
    };

    /**
     * Get popup state
     * @param {string} name
     */
    $scope.state = (name) => {
      return $scope.$$state[name];
    };

    /**
     * Show popup when:
     * 1. Cookie consent feature is enabled by merchant, and
     * 2. User never selects and accepts the preference (no cookie)
     */
    if (
      cookieConsentService.enabled &&
      !Array.isArray(cookieConsentService.preferences)
    ) {
      $scope.$$state.showPopup = true;
    }
  },
]);
