app.service('localDatetimeServices', [
  function () {
    var status = { showTooltip: false };
    var $window = $(window);

    return {
      mountTooltip: function ($target, title) {
        $target.attr('data-toggle', 'tooltip');
        $target.attr('data-placement', 'top');
        $target.attr('data-html', 'true');
        $target.attr('title', title);

        if (is.desktop()) {
          $target.tooltip();
        } else {
          // bind click event on mobile only
          $target.on('click', function () {
            if (status.showTooltip) return;
            status.showTooltip = true;
            $target.tooltip('show');
            $target.on('blur', hideTooltip);
            $window.on('scroll', hideTooltip);
          });

          $target.on('$destroy', hideTooltip);

          function hideTooltip() {
            // clear up and unbind events
            $target.tooltip('hide');
            $window.off('scroll', hideTooltip);
            $target.off('blur', hideTooltip);
            status.showTooltip = false;
          }
        }
      },
    };
  },
]);
