app.service('locationService', [
  function () {
    this.getQueryParams = function () {
      var urlParams = {};
      _.each(
        window.location.search.replace(/.*\?/, '').split('&'),
        function (chunk) {
          var parts = chunk.split('=');
          if (parts.length == 2) {
            urlParams[parts[0]] = parts[1];
          }
        },
      );
      return urlParams;
    };

    this.queryParamsMapping = {
      is_preview: {
        v1PageBuilder: '1',
        v2PageBuilder: '2',
      },
    };
  },
]);
