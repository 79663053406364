import './services.product'
import './services.anchor-smooth-scroll'
import './services.checkout.js.coffee'
import './services.cart'
import './services.tracker'
import './services.feature'
import './services.pnotify'
import './services.hiido-tracker'
import './services.product-preorder'
import './services.product-stock'

do ->
  ProductPagesController = (
    $rootScope
    $scope
    $timeout
    product
    pageId
    ckId
    $filter
    productService
    $location
    anchorSmoothScroll
    checkoutService
    cartService
    trackerService
    featureService
    $q
    multipleSelection
    pnotifyService
    flash
    hiidoTrackerService
    slFeatureService
    deliveryOptions
    deliveryOptionId
    productPreorderService
    productStockService
  ) ->

    cartFormLoaded = false;
    $scope.preorderItems = {};

    $timeout (() ->
      $('.title-container.ellipsis').each () ->
        desiredHeight = if $(this).attr('data-max-height') then parseInt($(this).attr('data-max-height'), 10) else 40
        $(this).dotdotdot
          wrap: 'letter'
          height: desiredHeight
          ellipsis: '...'
    ), 100

    checkFixedFooter = () ->
      checkout = document.getElementById('product-page-checkout')
      windowHeight = document.documentElement.clientHeight
      box = angular.element( document.querySelector('#buynow') ).parents('.container')
      scrollWindowTop = document.documentElement.scrollTop || document.body.scrollTop
      return if (box == undefined || checkout == undefined)
      # Dynamic control footer `buynow` button for mobile
      buttonBottomToNextPage = 45
      if checkout.offsetTop < scrollWindowTop + windowHeight
        document.getElementById('buynow-footer').style.visibility = 'hidden'
      else
        if (box.offset().top == 0)
          if scrollWindowTop + buttonBottomToNextPage > box.height()
            document.getElementById('buynow-footer').style.visibility = 'visible'
          else
            document.getElementById('buynow-footer').style.visibility = 'hidden'
        else
          if scrollWindowTop + windowHeight < box.offset().top + box.height() - buttonBottomToNextPage ||
             scrollWindowTop > box.offset().top + box.height() - buttonBottomToNextPage
            document.getElementById('buynow-footer').style.visibility = 'visible'
          else
            document.getElementById('buynow-footer').style.visibility = 'hidden'

    getIsBindedWarehouse = (targetId) ->
      currentOption = deliveryOptions.find((option) -> option._id == targetId);
      currentOption?.instore_pickup_with_binded_warehouse || false;

    $scope.scrollToHash = (id) ->
      anchorSmoothScroll.scrollTo(id)

    $scope.outOfStockHint = () ->
      if slFeatureService.hasFeature('preorder_limit') && $scope.product.is_preorder
        $filter('translate')('products.quick_cart.out_of_number_hint')
      else
        $filter('translate')('products.quick_cart.out_of_stock_hint')

    $scope.lowStockHint = () ->
      if slFeatureService.hasFeature('preorder_limit') && $scope.product.is_preorder
        $filter('translate')('products.quick_cart.out_of_number_hint')
      else
        $filter('translate')('products.quick_cart.low_stock_hint')

    $scope.showPreorderHint = (variation) ->
      if !slFeatureService.hasFeature('preorder_limit') || $scope.itemQuantity[variation.key] == 0
        $scope.preorderItems[variation.key] = false
        return false;
      productStockInfo = productStockService.getProductStockInfo($scope.product, variation)
      isPreorderQuantity = productPreorderService.isPreorderWithQuantity({ ...productStockInfo }, $scope.itemQuantity[variation.key])
      $scope.preorderItems[variation.key] = isPreorderQuantity
      return isPreorderQuantity

    $scope.checkEnoughStock = (variation) ->
      return true if (!$scope.stock || $scope.product.unlimited_quantity)
      itemQuantity = if variation then $scope.itemQuantity[variation.key] else $scope.itemQuantity
      itemSock = if variation then $scope.stock[variation.key].quantity else $scope.product.quantity
      stockQuantity = parseInt(itemSock, 10)
      productStockInfo = productStockService.getProductStockInfo($scope.product, variation)
      return true if $scope.product.unlimited_quantity || productService.isOutOfStockOrderable(productStockInfo) || stockQuantity >= itemQuantity
      preorderLimit = if featureService.hasFeature('preorder_limit') then productStockInfo.preorder_limit else 0;
      return itemQuantity <= (stockQuantity + preorderLimit)

    initBuyNow = () ->
      buynow = angular.element( document.querySelector('#buynow') )
      buynow.bind('click', () ->
        value = $scope.itemQuantity * cartService.getItemPrice({
          product: product
          variation: $scope.variationSelected
        }).dollars
        $scope.scrollToHash('product-page-checkout')
      ) if buynow
      if document.getElementById('buynow-footer')
        $(window).on 'scroll', _.debounce(checkFixedFooter, 300)

    informPartialReload = (keys) ->
      if keys == 'all'
        $scope.reloading = 3
        shouldReloadAll = keys
      else
        $scope.reloading = keys.length if Array.isArray(keys)
      keys = [] if not keys?
      $scope.$broadcast("checkout.cart.promotions.reload") if shouldReloadAll or _.contains(keys, "promotions")
      $scope.$broadcast("checkout.cart.form.reload") if shouldReloadAll or _.contains(keys, "form")

      # ensure cart.summary load after cart.form
      $scope.$broadcast("checkout.cart.summary.reload") if cartFormLoaded && (shouldReloadAll or _.contains(keys, "summary"))

    gaFbViewContent = () ->
      # Only run this here if there are no variations, as the same logic is inside variation-dropdown, which isnt rendered when no variations
      if (!$scope.product.variations || $scope.product.variations.length < 1 || $scope.product.hide_price)
        price = cartService.getItemPrice({product: $scope.product});
        dollars = if _.isObject(price) then price.dollars else 0;
        trackerService.gaSendDetails($scope.product, null, null);
        trackerService.fbSendViewContent($scope.product, dollars);
        trackerService.track({
          type: trackerService.generalEventType.VIEW_CONTENT,
          data: {
            product: $scope.product, 
            value: dollars
          },
        });

    $scope.reload = () ->
      informPartialReload('all')

    $scope.addedVariationsCount = () ->
      Object.keys($scope.itemQuantity).reduce(
        (count, id) -> if $scope.itemQuantity[id] > 0 then count + 1 else count,
        0
      )
    $scope.validateItem = {
      items: [],
      message: ''
    }
    $scope.isBindedWarehouseInStorePickup = getIsBindedWarehouse(deliveryOptionId);
    $scope.product = product
    $scope.product.digest_variations = []
    $scope.enoughStock = true
    $scope.enablePurchaseLimitCampaign = slFeatureService.hasFeature('purchase_limit_campaign')
    $scope.pageId = pageId
    $scope.showNotEnoughStock = false
    $scope.showReachedPurchaseLimit = false
    $scope.showPurchaseLimitByCustomerInvalid = false
    checkoutService.pageId = pageId
    cartService.pageId = pageId
    $scope.state =
      isCartLoading: false
      cartCleared: false
    $scope.reloading = 0
    $scope.multiSelect = multipleSelection
    if document.getElementById('buynow-footer')
      checkFixedFooter()
    initBuyNow()
    gaFbViewContent()

    if !product.variations.length
      $scope.itemQuantity = 1
      $scope.itemPrice = cartService.getItemPrice({product: product})
    else
      $scope.itemQuantity = {}
      $scope.variationsPriceMappings = {}

      product.variations.forEach((variation) ->
        if multipleSelection
          $scope.itemQuantity[variation.key] = 0
      
        $scope.variationsPriceMappings[variation.key] = cartService.getItemPrice({
          product: product,
          variation: variation
        })
      )

    if (multipleSelection || $scope.product.variations.length)
      $scope.$watchCollection('itemQuantity', (itemQuantity) ->
        $scope.state.isCartLoading = true if multipleSelection
        $scope.cartIsEmpty = Object.keys(itemQuantity).every((key) -> itemQuantity[key] == 0)
      )

    productPageCheckoutBtn = Ladda.create(document.getElementById("product-page-checkout-btn"))
    $scope.$watch('reloading', (newValue) ->
      if newValue == 0 then productPageCheckoutBtn.stop() else productPageCheckoutBtn.start()
    )
    $scope.$on "checkout.cart.form.changed", () -> validateCart(() -> 
      informPartialReload(["promotions", "summary"])
      $scope.applePayCheckFailed = false
    )
    $scope.$on "checkout.cart.summary.changed", (() -> informPartialReload(["promotions"]))
    $scope.$on "checkout.cart.coupon.remove", (() -> validateCart(() -> informPartialReload(["promotions", "summary"])))
    $scope.$on "checkout.cart.form.loaded", (() ->
      if !cartFormLoaded
        cartFormLoaded = true
        $scope.$broadcast("checkout.cart.summary.reload")

      $scope.reloading-- if $scope.reloading > 0
    )
    $scope.$on "checkout.cart.summary.loaded", (() -> $scope.reloading-- if $scope.reloading > 0)
    $scope.$on "checkout.cart.content.loaded", (() ->
                                                  $scope.reloading-- if $scope.reloading > 0
                                                  if $('.promotion').length > 0
                                                    $('.line').show()
                                                    $('#page-promotions').show()
                                                  else
                                                    $('.line').hide()
                                                    $('#page-promotions').hide()
                                                    )


    $rootScope.$on('cart.form.sfexpress.location.change', (event, locationCode) ->
      # disable checkout button if sfLocation is not selected
      $scope.buyNowBtnDisabled = (locationCode == '?' || !locationCode)
    )

    $rootScope.$on('cart.form.pakpobox.location.change', (event, locationCode) ->
      # disable checkout button if pakpobox location is not selected
      $scope.buyNowBtnDisabled = (locationCode == '?' || !locationCode)
    )

    $rootScope.$on('cart.form.country.change', () ->
      $scope.buyNowBtnDisabled = false;
    )

    $rootScope.$on('cart.form.delivery.change', (_, deliveryOptionId) ->
      $scope.isBindedWarehouseInStorePickup = getIsBindedWarehouse(deliveryOptionId);
      $scope.buyNowBtnDisabled = false;
    )

    $rootScope.$on('checkout.cart.form.changing', () ->
      $scope.state.isCartLoading = true
    ) if multipleSelection

    updateValidateItems = (type, payload) ->
      switch type 
        when "RESET"
          $scope.validateItem = { items: [], message: '' };
        when "PUSH"
          items = $scope.validateItem.items.concat(payload);
          $scope.validateItem = Object.assign({}, $scope.validateItem, {
            items: items
          })
      
    getInStorePickupChannelId = () ->
      channelId = angular.element(document.querySelector('#selected-store-target-channel-id')).val() || ''

    validateCart = (callback) ->
      checkoutService.requestValidate()
        .then ((res) ->
          $scope.errors = res.data
          document.getElementById('checkout-errors').style.display = 'none'
          callback() if typeof callback == "function" )
        .catch ((res) ->
          $scope.errors = res.data
          document.getElementById('checkout-errors').style.display = 'block'
          $scope.reload() )
        .finally () ->
          errorMsgs = _.values($scope.errors)
          errorMsgs = _.reduce(errorMsgs, ((memo, msg) -> memo + '<i class="fa fa-exclamation-circle">&nbsp' + msg + '</i><br>') , '')
          document.getElementById('checkout-errors').innerHTML = errorMsgs
    
    deviceCanUseApplePay = !!window.ApplePaySession

    $scope.validateInstorePickupDelivery = () ->
      if (!$scope.isBindedWarehouseInStorePickup)
        return true
      channelId = getInStorePickupChannelId()
      !!channelId

    $rootScope.getAddToCartButtonText = (isBuyNowButtonEnabled, availableType) ->
      if ($scope.multiSelect)
        isPreorderVariant = Object.values($scope.preorderItems).some(Boolean)
        buttonText = if isPreorderVariant then 'products.quick_cart.preorder_now' else 'product.add_to_cart'
        return $filter('translate')(buttonText)

      productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      itemQuantity = if ($scope.variationSelected) then $scope.itemQuantity[$scope.variationSelected.key] else $scope.itemQuantity
      productPreorderService.getAddToCartButtonText({
        productStockInfo,
        addItemQuantity: itemQuantity,
        isBuyNowButtonEnabled,
        availableType
      });

    $scope.includePreorderItem = () ->
      productStockInfo = productStockService.getProductStockInfo($scope.product, $scope.variationSelected)
      itemQuantity = if ($scope.variationSelected) then $scope.itemQuantity[$scope.variationSelected.key] else $scope.itemQuantity
      return productPreorderService.isPreorderWithQuantity(productStockInfo, itemQuantity)

    $scope.checkout = (isDraft) ->
      updateValidateItems("RESET")
      $scope.showNotEnoughStock = false
      $scope.showReachedPurchaseLimit = false
      $scope.showPurchaseLimitByCustomerInvalid = false

      return if isDraft || $scope.reloading > 0
      
      if ($('#order-payment-method').attr('attr-payment-method') == 'isNewWebApplePay' && !deviceCanUseApplePay)
        checkoutErrorDom = document.getElementById('checkout-errors')
        checkoutErrorDom.innerHTML = $filter('translate')('checkout.payments.apple_pay.not_supported')
        checkoutErrorDom.style.display = 'block'
        $scope.applePayCheckFailed = true
        return
      variation_id = if ($scope.variationSelected) then $scope.variationSelected.key else ""

      variationId = if product.variations.length then product.variations[0].key else ''
      purchaseLimit = productService
        .checkStock($scope.product._id, variationId, pageId)
        .then((result) -> (result.data.total_variations_cart_qty > result.data.max_order_quantity) && result.data.max_order_quantity != -1)

      if $scope.enablePurchaseLimitCampaign
        purchaseQuantity = if product.variations.length then $scope.itemQuantity[variationId] else $scope.itemQuantity

        productService.checkPurchaseLimitByCustomer($scope.product._id, purchaseQuantity)
          .then((result) ->
            $scope.purchaseLimitByCustomerInvalid = result.data.purchase_limit_invalid
            $scope.purchaseLimitQuantity = result.data.purchase_limit_quantity
            $scope.canPurchaseQuantity = result.data.can_purchase_quantity
            $scope.purchasedRecordQuantitySum = result.data.purchased_record_quantity_sum

            if $scope.canPurchaseQuantity > 0
              if $scope.purchasedRecordQuantitySum == 0
                $scope.purchaseLimitError = $filter('translate')('products.purchase_limit_hint', { limit: $scope.purchaseLimitQuantity })
              else
                $scope.purchaseLimitError = $filter('translate')('purchase_limit_hint.purchasable', { limit: $scope.purchaseLimitQuantity, purchasable_qty: $scope.canPurchaseQuantity })
            else
              $scope.purchaseLimitError = $filter('translate')('products.purchase_limit_hint_can_not_purchase', { limit: $scope.purchaseLimitQuantity })
          )
      
      if (!$scope.validateInstorePickupDelivery())
        updateValidateItems("PUSH", { code: 'REQUIRED' })
        
      purchaseLimit
        .then((reachedPurchaseLimit) ->
          $scope.reachedPurchaseLimit = reachedPurchaseLimit
          variationsInfo = if product.variations.length then product.variations.map((variation) -> { product_id: product._id, variation_id: variation.key }) else [{ product_id: product._id }]
          if ($scope.isBindedWarehouseInStorePickup)
            channelId = getInStorePickupChannelId()
            variationsInfo = if channelId then variationsInfo.map((variation) -> Object.assign(variation, { target_channel_id: channelId })) else variationsInfo;

          productService.checkStocks(variationsInfo)
            .then (result) ->
              hiidoTrackerService.expressCheckout.checkout()
              $scope.stock = {}
              result.data.forEach((variationStock, i) ->
                $scope.stock[result.config.data.products[i].variation_id] = variationStock
              );

              if (!$scope.product.variations.length)
                $scope.product.quantity = result.data[0]?.quantity || 0;
                $scope.enoughStock = $scope.checkEnoughStock()
              else
                $scope.enoughStock = product.variations
                  .filter((variation) -> $scope.itemQuantity[variation.key] > 0)
                  .every $scope.checkEnoughStock

              if (!$scope.enoughStock)
                $scope.showNotEnoughStock = true
                if ($scope.isBindedWarehouseInStorePickup)
                  updateValidateItems("PUSH", { code: cartService.ERROR_CODE.PRODUCT_INSUFFICIENT_STOCK })
                return
              else if $scope.reachedPurchaseLimit
                $scope.showReachedPurchaseLimit = true
                return
              else if $scope.purchaseLimitByCustomerInvalid
                $scope.showPurchaseLimitByCustomerInvalid = true
                return
              if $scope.enoughStock && $scope.validateInstorePickupDelivery()
                items = $rootScope.currentCart.getItems()
                subtotal = $rootScope.currentCart.getSubtotal()
                trackerService.fbAddPaymentInfo(items, subtotal)
                trackerService.track({
                  type: trackerService.generalEventType.ADD_BILLING,
                  data: {
                    items,
                    subtotal,
                  },
                })
                trackerService.track({
                  type: trackerService.generalEventType.ADD_SHIPPING,
                  data: {
                    items,
                    subtotal,
                  },
                })
                ckIdParam = if ckId then '&ckid=' + ckId else ''
                validateCart(() -> window.location = '/checkout?page_id=' + pageId + ckIdParam)
              else
                validateCart()
        )

  ProductPagesController.$inject = [
    '$rootScope'
    '$scope'
    '$timeout'
    'product'
    'pageId'
    'ckId'
    '$filter'
    'productService'
    '$location'
    'anchorSmoothScroll'
    'checkoutService'
    'cartService'
    'trackerService'
    'featureService'
    '$q'
    'multipleSelection'
    'pnotifyService'
    'flash'
    'hiidoTrackerService'
    'slFeatureService'
    'deliveryOptions'
    'deliveryOptionId'
    'productPreorderService'
    'productStockService'
  ]

  app.controller('ProductPagesController', ProductPagesController)
