###
  This directive is only used for handle link highlight from pre-rendered link as the view
  from Rails is cached
###
app.directive("linkHighlight", [
  '$window'
  (
    $window
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        link = attrs.linkHighlight || element.attr('href')
        if (link) && encodeURI(link.split('?')[0]) == $window.location.pathname
          element.addClass('active')
    }
])