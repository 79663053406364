app.service('addonProductService', [
  'mainConfig'
  (
    mainConfig
  ) ->
    {
      quantityLimitReached: (addonItemQty, mainProductQty)->
        if mainConfig.merchantData.addon_limit_enabled
          addonItemQty == mainProductQty or addonItemQty > mainProductQty
        else 
          false
        
      quantityLimitExceeded: (addonItemQty, mainProductQty)->
        if mainConfig.merchantData.addon_limit_enabled
          addonItemQty > mainProductQty
        else
          false
    }
])
