import './services.buy-now';
import './services.product-set';
import './services.fast-checkout';
import './services.ga';
import './services.tracker';
import './services.addon_product.js.coffee';
import './services.product';
import './services.cart';
app.directive('productsFormAddonProduct', [
  '$filter',
  '$lightbox',
  '$q',
  'cartService',
  'productService',
  'mainConfig',
  'addonProductService',
  'trackerService',
  'Analytics',
  'gaService',
  'slFeatureService',
  '$rootScope',
  'fastCheckoutService',
  'productSetService',
  'buyNowService',
  function (
    $filter,
    $lightbox,
    $q,
    cartService,
    productService,
    mainConfig,
    addonProductService,
    trackerService,
    Analytics,
    gaService,
    slFeatureService,
    $rootScope,
    fastCheckoutService,
    productSetService,
    buyNowService,
  ) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        scope.addon_errors = {};
        // Set main product initial quantity
        scope.addAddonProductQuantity[scope.product._id] = 1;
        // Get main product change item quantity and put into array
        scope.$watch('addItemQuantity', function (newQty, oldQty) {
          newQty = parseInt(newQty);
          oldQty = parseInt(oldQty);
          for (var productId in scope.addAddonProductQuantity) {
            if (
              mainConfig.merchantData.addon_limit_enabled &&
              productId != scope.product._id &&
              scope.getSelectedAddonProductQuantity() > newQty
            ) {
              if (newQty < scope.getSelectedAddonProductQuantity()) {
                // clear selected addon when sum of selected addons's qty is more than main product's qty
                for (var property in scope.addAddonProductQuantity) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (scope.addAddonProductQuantity.hasOwnProperty(property)) {
                    scope.addAddonProductQuantity[property] =
                      property === scope.product._id ? oldQty : 0;
                  }
                }
              }
            }
          }
          var addItemQty = newQty;
          scope.addAddonProductQuantity[scope.product._id] = addItemQty;
        });

        scope.onProductImageClicked = function (productId) {
          var product = scope.product;
          if (productId !== product._id) {
            var addonPromotion = _.find(
              scope.product.addon_promotions,
              function (promotion) {
                return promotion.addon_product_id == productId;
              },
            );
            if (addonPromotion) {
              product = addonPromotion.discountable_products[0];
            }
          }
          if (product) {
            $lightbox.open(product.media, 0);
          }
          return;
        };

        scope.getAddonProductsError = function () {
          var errorMessages = [];

          for (var key in scope.addon_errors) {
            switch (key) {
              case 'main_product_out_of_stock':
              case 'larger_addon_product':
                errorMessages.push(
                  $filter('translate')('product.addon_products.errors.' + key),
                );
                break;
              case 'addon_product_out_of_stock':
                _.keys(scope.addon_errors.addon_product_out_of_stock).map(
                  function (key) {
                    var elements = element.find('#' + key + ' .title');
                    var title = elements[0].innerText;
                    errorMessages.push(
                      $filter('translate')(
                        'product.addon_products.errors.out_of_stock',
                        {
                          title: title,
                        },
                      ),
                    );
                  },
                );
                break;
              case 'reached_max_purchase_quantity':
                errorMessages.push(
                  $filter('translate')('product.addon_products.errors.' + key, {
                    message: scope.addon_errors[key],
                  }),
                );
                break;
            }
          }
          return errorMessages.join('<br>');
        };

        scope.getSelectedAddonProductQuantity = function () {
          var total = 0;
          for (var key in scope.addAddonProductQuantity) {
            if (key == scope.product._id) {
              continue;
            } // Skip main product in checking total addon product quantity
            var quantity = scope.getAddonProductQuantity(key);
            total += quantity;
          }
          return total;
        };
        scope.getAddonProductQuantity = function (productId) {
          var quantity = parseInt(scope.addAddonProductQuantity[productId], 10);
          if (_.isNaN(quantity)) {
            quantity = 0;
            scope.addAddonProductQuantity[productId] = quantity;
          }
          return quantity;
        };

        scope.$watchCollection(
          'addAddonProductQuantity',
          function (newObj, oldObj) {
            var addonItemTotalQty = 0;
            scope.addon_errors = {};

            angular.forEach(newObj, function (val, productId) {
              var qty = parseInt(val, 10);

              if (qty < 0) {
                scope.addAddonProductQuantity[productId] = 0;
              } else if (
                !mainConfig.merchantData.addon_limit_enabled ||
                (mainConfig.merchantData.addon_limit_enabled &&
                  isValidAfterUpdateProductQty(productId, qty))
              ) {
                scope.addAddonProductQuantity[productId] = qty;

                if (productId !== scope.product._id) {
                  addonItemTotalQty += qty;
                }
              } else {
                scope.addAddonProductQuantity[productId] = oldObj[productId];
              }
            });

            scope.state.addonItemEnabled =
              scope.isAddonItemEnabled(addonItemTotalQty);
          },
          true,
        );

        scope.decAddonProductQuantity = function (productId) {
          alterAddonProductQuantity(productId, -1);
        };
        scope.incAddonProductQuantity = function (productId) {
          alterAddonProductQuantity(productId, 1);
        };

        scope.addonLimitReached = function () {
          return addonProductService.quantityLimitReached(
            scope.getSelectedAddonProductQuantity(),
            scope.getAddonProductQuantity(scope.product._id),
          );
        };

        function addonLimitExceeded() {
          return addonProductService.quantityLimitExceeded(
            scope.getSelectedAddonProductQuantity(),
            scope.getAddonProductQuantity(scope.product._id),
          );
        }

        function alterAddonProductQuantity(productId, step) {
          var quantity = scope.getAddonProductQuantity(productId);
          if ((quantity <= 0 && step < 0) || (quantity >= 99999 && step > 0)) {
            return;
          }
          if (
            scope.addAddonProductQuantity[scope.product._id] <
              scope.getSelectedAddonProductQuantity() + step &&
            mainConfig.merchantData.addon_limit_enabled
          ) {
            return;
          }
          scope.addAddonProductQuantity[productId] = quantity + step;
          scope.validateProductQuantity(productId);
          scope.state.addonItemEnabled = scope.isAddonItemEnabled(
            scope.getSelectedAddonProductQuantity(),
          );
          scope.addon_errors = {};
        }

        scope.validateProductQuantity = function (productId) {
          if (scope.getAddonProductQuantity(productId) < 0) {
            scope.addAddonProductQuantity[productId] = 0;
          }
        };

        // Submit items with addon
        scope.submitAddonProducts = function (
          event,
          isBuyNow,
          isSPBFastCheckout,
        ) {
          var variationId = scope.variationSelected
            ? scope.variationSelected.key
            : '';
          scope.loading = true;
          scope.addon_errors = {};

          var mainProductQuantity = scope.getAddonProductQuantity(
            scope.product._id,
          );
          var productStocksToCheck = [
            { product_id: scope.product._id, variation_id: variationId },
          ];

          if (productSetService.isProductSetEnabled(scope.product)) {
            productStocksToCheck[0].selected_child_products =
              productSetService.generateProductSetParams(
                scope.selectedProductSetData,
              );
          }

          if (productSetService.isProductSetRevampEnabled(scope.product)) {
            productStocksToCheck[0].selected_child_products =
              scope.selectedProductSetData;
          }

          for (var productId in scope.addAddonProductQuantity) {
            if (productId === scope.product._id) {
              continue;
            } // Skip main product as the map only cater quantity, we added the product with variation already
            var quantity = scope.getAddonProductQuantity(productId);
            if (quantity > 0) {
              productStocksToCheck.push({ product_id: productId });
            }
          }

          var isFastCheckoutCart =
            (isBuyNow && fastCheckoutService.isEnableEc) ||
            (isSPBFastCheckout && fastCheckoutService.isSpbEnabled);
          // Check stock for each product and notify for out-of-stock
          productService
            .checkStocks(productStocksToCheck, isFastCheckoutCart)
            .then(
              function (response) {
                scope.reachedPurchaseLimit = false;
                var isStockEnough = true;
                var limitExceeded = false;
                var reachLimitPurchase = false;
                var canPurchase = true;
                scope.addon_errors['addon_product_out_of_stock'] = {};
                // check stock of products
                _.each(response.data, function (data) {
                  var isMainProduct = data.product_id == scope.product._id;
                  var avaiableQty = parseInt(data.quantity);
                  var maxOrderQuantity = parseInt(data.max_order_quantity);
                  var selectedProductQty =
                    scope.addAddonProductQuantity[data.product_id];

                  reachLimitPurchase =
                    selectedProductQty + data.total_variations_cart_qty >
                      maxOrderQuantity && maxOrderQuantity != -1;
                  if (reachLimitPurchase) {
                    if (isMainProduct) {
                      scope.addon_errors['reached_max_purchase_quantity'] =
                        maxOrderQuantity;
                    }
                  }

                  if (
                    !(data.unlimited_quantity || data.out_of_stock_orderable) &&
                    selectedProductQty + data.cart_quantity > avaiableQty &&
                    avaiableQty != -1
                  ) {
                    // add out of stock message
                    if (isMainProduct) {
                      scope.addon_errors['main_product_out_of_stock'] = true;
                    } else {
                      scope.addon_errors['addon_product_out_of_stock'][
                        data.product_id
                      ] = true;
                    }
                    isStockEnough = false;
                  }
                  if (
                    mainConfig.merchantData.addon_limit_enabled &&
                    addonLimitExceeded()
                  ) {
                    scope.addon_errors['larger_addon_product'] = true;
                    limitExceeded = true;
                  }
                  if (!isStockEnough || limitExceeded || reachLimitPurchase) {
                    canPurchase = false;
                  }
                });
                if (!canPurchase) {
                  scope.loading = false;
                  return;
                }

                var mainProductQuantityData = _.find(
                  response.data,
                  function (product) {
                    return product.product_id === scope.product._id;
                  },
                );
                var result = cartService.checkStockResult(
                  scope.addItemQuantity,
                  mainProductQuantityData,
                  isFastCheckoutCart,
                );
                scope.reachedPurchaseLimit = result.reachedPurchaseLimit;
                scope.notEnoughStockQty = result.notEnoughStockQty;
                scope.quantityOfStock = result.quantityOfStock;
                scope.orderQuantityStatus = result.orderQuantityStatus;

                if (scope.variationSelected) {
                  scope.variationSelected.cart_quantity =
                    mainProductQuantityData.cart_quantity;
                } else {
                  scope.product.cart_quantity =
                    mainProductQuantityData.cart_quantity;
                }

                // should not add item to cart
                if (
                  result.notEnoughStockQty >= 0 ||
                  scope.reachedPurchaseLimit
                ) {
                  scope.loading = false;
                  return;
                }

                // Add items to cart
                var itemsData = [];
                _.each(response.data, function (data) {
                  if (data.product_id == scope.product._id) {
                    var itemData = {
                      product_id: scope.product._id,
                      quantity: mainProductQuantity,
                      variation_id: scope.variationSelected,
                      blacklisted_payment_option_ids:
                        scope.product.blacklisted_payment_option_ids,
                      blacklisted_delivery_option_ids:
                        scope.product.blacklisted_delivery_option_ids,
                      type: scope.product.subscription_enabled
                        ? 'subscription_product'
                        : 'product',
                    };
                    // Product Set
                    if (productSetService.isProductSetEnabled(scope.product)) {
                      itemData.type = 'product_set';
                      itemData.productSetData =
                        productSetService.generateProductSetParams(
                          scope.selectedProductSetData,
                        );
                    }

                    if (
                      productSetService.isProductSetRevampEnabled(scope.product)
                    ) {
                      itemData.type = 'product_set';
                      itemData.productSetData = scope.selectedProductSetData;
                    }
                    // Main product
                    itemsData.push(itemData);
                  } else {
                    // Addon products
                    itemsData.push({
                      product_id: data.product_id,
                      quantity: scope.getAddonProductQuantity(data.product_id),
                      variation_id: null,
                      blacklisted_payment_option_ids: [],
                      blacklisted_delivery_option_ids: [],
                      type: 'addon_product',
                    });
                  }
                });
                // Pass id to keep track of main product to differentiate from addon items
                if (isBuyNow) {
                  itemsData.isFastCheckoutCart = fastCheckoutService.isEnableEc;
                }

                if (isSPBFastCheckout) {
                  itemsData.isFastCheckoutCart =
                    fastCheckoutService.isSpbEnabled;
                }
                const itemPrice = cartService.getItemPrice({
                  product: scope.product,
                  variation: scope.variationSelected,
                });
                const mainProductItem = _.find(itemsData, function (data) {
                  return data.product_id === scope.product._id;
                });
                const value = mainProductItem.quantity * itemPrice.dollars;
                cartService
                  .addItems(scope.product._id, { items: itemsData, value })
                  .then(function (res) {
                    // Promoise for updating scope
                    for (var property in scope.addAddonProductQuantity) {
                      if (
                        // eslint-disable-next-line no-prototype-builtins
                        scope.addAddonProductQuantity.hasOwnProperty(property)
                      ) {
                        scope.addAddonProductQuantity[property] =
                          property === scope.product._id ? 1 : 0;
                      }
                    }

                    if (scope.variationSelected) {
                      scope.variationSelected.cart_quantity =
                        scope.variationSelected.cart_quantity +
                        scope.addItemQuantity;
                    } else {
                      scope.product.cart_quantity =
                        scope.product.cart_quantity + scope.addItemQuantity;
                    }

                    scope.addItemQuantity = 1;
                    scope.state.addonItemEnabled = false;
                    scope.loading = false;
                    if (!isBuyNow && !isSPBFastCheckout) {
                      jQuery.sidr('open', 'cart-panel');
                      if (
                        mainConfig.merchantData.current_theme_key == 'sangria'
                      ) {
                        //show mobile cart when add item to cart for sangria theme
                        $('.js-navbar-mobile .icon-bag-mobile').click();
                      }
                    }
                    return res;
                  })
                  .then(function (res) {
                    // Promise for updating trackers
                    var mainProductItem = _.find(itemsData, function (data) {
                      return data.product_id === scope.product._id;
                    });
                    var itemPrice = cartService.getItemPrice({
                      product: scope.product,
                      variation: scope.variationSelected,
                    });
                    var item = $filter('filter')(res.items, {
                      product_id: scope.product._id,
                      variation_id: variationId,
                    })[0];
                    if (slFeatureService.hasFeature('data_layer_info')) {
                      var gaAddItemData = cartService.getGaItemData({
                        product: scope.product,
                        variant: scope.variationSelected,
                      });

                      $rootScope.$emit('add.item.to.cart', gaAddItemData);

                      $rootScope.$emit(
                        'add.addon.item.to.cart',
                        itemsData
                          .filter(function (item) {
                            return item.product_id !== scope.product._id;
                          })
                          .map(function (item) {
                            var addOnItem = scope.product.addon_promotions.find(
                              function (curAddOnItem) {
                                return (
                                  curAddOnItem.addon_product_id ===
                                  item.product_id
                                );
                              },
                            );

                            return {
                              title: addOnItem.title_translations,
                              sku:
                                addOnItem.discountable_products &&
                                addOnItem.discountable_products[0]
                                  ? addOnItem.discountable_products[0].sku
                                  : '',
                              currency: gaAddItemData.currency,
                              quantity: item.quantity,
                              price:
                                addOnItem.discounted_price &&
                                addOnItem.discounted_price.dollars,
                            };
                          }),
                      );
                    }
                    trackerService.track({
                      type: trackerService.generalEventType.ADD_TO_CART,
                      data: {
                        items: [
                          {
                            product: scope.product,
                            quantity: mainProductItem.quantity,
                            value: mainProductItem.quantity * itemPrice.dollars,
                            variationSelected: scope.variationSelected,
                          },
                        ],
                        eventId: res.event_id,
                      },
                    });

                    //  Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
                    if (Analytics.configuration.enhancedEcommerce) {
                      try {
                        gaService.setUserId();
                        Analytics.addProduct(
                          productService.getSku(
                            item.product._id,
                            item.product.sku,
                            item.variation,
                          ),
                          $filter('translateModel')(
                            item.product.title_translations,
                          ),
                          '',
                          '',
                          productService.getVariationName(item.variation),
                          itemPrice.label.substr(3).replace(',', ''),
                          mainProductItem.quantity.toString(),
                          '',
                          '0',
                        );
                        Analytics.trackCart('add');
                        // eslint-disable-next-line no-empty
                      } catch (e) {}
                    }
                    if (isBuyNow) {
                      $rootScope.$broadcast('clicked_buy_now_button');
                    } else if (
                      fastCheckoutService.shouldShowSpbModal() ||
                      buyNowService.shouldShowBuyNowModal()
                    ) {
                      buyNowService.openModal();
                    }
                  })
                  .finally(function () {
                    scope.loading = false;
                  });
              },
              function () {
                scope.loading = true;
              },
            );
        };

        scope.isAddonItemEnabled = function (quantity) {
          return quantity > 0 && scope.addItemQuantity > 0;
        };

        scope.isAddonSelected = {};
        // setup addon checkbox setter/getters
        _.keys(scope.addAddonProductQuantity).map(function (key) {
          Object.defineProperty(scope.isAddonSelected, key, {
            get: function () {
              return scope.addAddonProductQuantity[key] > 0;
            },
            set: function () {
              if (scope.addAddonProductQuantity[key] > 0) {
                scope.addAddonProductQuantity[key] = 0;
                scope.state.addonItemEnabled = scope.isAddonItemEnabled(
                  scope.getSelectedAddonProductQuantity(),
                );
              } else if (scope.addAddonProductQuantity[key] == 0) {
                if (
                  !mainConfig.merchantData.addon_limit_enabled ||
                  scope.getSelectedAddonProductQuantity() <
                    scope.addAddonProductQuantity[scope.product._id]
                ) {
                  scope.addAddonProductQuantity[key] = 1;
                  scope.state.addonItemEnabled = scope.addItemQuantity > 0;
                }
              }
            },
          });
        });

        function isValidAfterUpdateProductQty(productId, qty) {
          return (
            scope.addAddonProductQuantity[scope.product._id] >=
            scope.getSelectedAddonProductQuantity() -
              scope.addAddonProductQuantity[productId] +
              qty
          );
        }
      },
    };
  },
]);
