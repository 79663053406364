import './services.merchant';
app.service('productReviewService', [
  '$http',
  'merchantService',
  function ($http, merchantService) {
    this.getProductReview = function (productId) {
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          merchantService.merchantId +
          '/products/' +
          productId +
          '/product_reviews',
      });
    };
  },
]);
