app.service('digestLife', function () {
  var didAfterDigestRegistered = false;
  var isRegistered = false;

  this.register = function ($scope, afterDigest) {
    if (isRegistered) return;
    isRegistered = true;
    $scope.$watch(function () {
      if (didAfterDigestRegistered) return;
      didAfterDigestRegistered = true;
      $scope.$$postDigest(function () {
        didAfterDigestRegistered = false;
        afterDigest();
      });
    });
  };

  this.unRegister = function () {
    isRegistered = false;
  };

  this.registerByScope = function ($scope, afterDigest) {
    if ($scope.state === undefined) {
      $scope.state = {};
    }
    if ($scope.state.isDigestLifeRegistered) return;
    $scope.state.isDigestLifeRegistered = true;
    $scope.$watch(function () {
      if ($scope.state.isAfterDigestRegistered) return;
      $scope.state.isAfterDigestRegistered = true;
      $scope.$$postDigest(function () {
        $scope.state.isAfterDigestRegistered = false;
        afterDigest();
      });
    });
  };

  this.unregisterByScope = function ($scope) {
    if ($scope.state === undefined) {
      $scope.state = {};
    }
    $scope.state.isDigestLifeRegistered = false;
  };
});
