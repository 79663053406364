import './service.user_reminder';
const { USER_REMINDER_TYPE } = require('../constants/user_reminder');

const MEMBER_REFERRAL_I18N_MAP = {
  user_credit: 'user_reminder.member_referral.user_credit',
  member_point: 'user_reminder.member_referral.member_point',
};

app.directive('userReminderDesktop', [
  'mainConfig',
  'slFeatureService',
  '$filter',
  'userReminderService',
  'staticResourceHost',
  '$window',
  function (
    mainConfig,
    slFeatureService,
    $filter,
    userReminderService,
    staticResourceHost,
    $window,
  ) {
    return {
      restrict: 'E',
      scope: {
        campaignRule: '=',
      },
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.user-reminder-desktop.html'),
      link: function (scope) {
        if ($window.innerWidth < 768) {
          return;
        }

        scope.ACTION_TEXT_I18N = {
          [USER_REMINDER_TYPE.MEMBER_REFERRAL]:
            'user_reminder.member_referral.action',
          [USER_REMINDER_TYPE.COUPON]: 'user_reminder.coupon.action',
        };

        scope.IMAGE_URL = {
          [USER_REMINDER_TYPE.MEMBER_REFERRAL]: `${staticResourceHost}web/assets/member-referral-popup-icon.svg`,
          [USER_REMINDER_TYPE.COUPON]: `${staticResourceHost}web/assets/coupon_reminder_icon.svg`,
        };

        scope.reminderType = null;

        const getReminderType = () =>
          userReminderService.getReminderType({
            campaignRule: scope.campaignRule,
          });

        userReminderService.fetchData().finally(() => {
          scope.reminderType = getReminderType();
        });

        scope.getContentText = () => {
          switch (scope.reminderType) {
            case USER_REMINDER_TYPE.MEMBER_REFERRAL:
              return $filter('translate')(
                MEMBER_REFERRAL_I18N_MAP[scope.campaignRule.reward_type],
                { value: scope.campaignRule.reward_value },
              );
            case USER_REMINDER_TYPE.COUPON:
              return $filter('translate')(
                mainConfig.currentUser
                  ? 'user_reminder.coupon.member'
                  : 'user_reminder.coupon.guest',
              );
            default:
              return '';
          }
        };

        scope.handleClose = () => {
          userReminderService.handleClose(scope.reminderType);
          scope.reminderType = getReminderType();
        };

        scope.handleClickActionButton = () => {
          userReminderService.handleAction(scope.reminderType);
          scope.handleClose();
        };
      },
    };
  },
]);
