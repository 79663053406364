/**
 * Directive to search product
 **/
app.directive('slDropdown', [
  'mainConfig',
  '$timeout',
  function (mainConfig, $timeout) {
    return {
      restrict: 'C',
      scope: {
        isCollapsed: '=',
        isMobileCollapsed: '=',
      },
      link: function (scope, element, attrs) {
        scope.state = {
          isCollapsed: attrs.isCollapsed !== 'false',
          isMobileCollapsed: !attrs.isMobileCollapsed
            ? attrs.isCollapsed !== 'false'
            : attrs.isMobileCollapsed !== 'false',
        };
        var render = _.debounce(function () {
          if (document.documentElement.clientWidth < 768) {
            element[
              (scope.state.isMobileCollapsed ? 'add' : 'remove') + 'Class'
            ]('is-collapsed');
          } else {
            element[(scope.state.isCollapsed ? 'add' : 'remove') + 'Class'](
              'is-collapsed',
            );
          }
        }, 100);

        element.on('click', '.sl-dropdown-toggle', function (e) {
          e.preventDefault();
          $timeout(function () {
            scope.state.isCollapsed =
              element.find('.sl-dropdown-list').attr('aria-expanded') !==
              'true';
            scope.state.isMobileCollapsed =
              element.find('.sl-dropdown-list').attr('aria-expanded') !==
              'true';
            render();
          }, 50);
        });

        element.on('$destroy', function () {
          element.off(); // Unbind events
        });

        $timeout(function () {
          if (!scope.isCollapsed) {
            // Default as false
            element
              .find('.sl-dropdown-list')
              .css('height', 'auto')
              .collapse('toggle');
          } else {
            element.find('.sl-dropdown-list').css('height', '0px');
          }
        });
        render();
      },
    };
  },
]);
