/**
 * Directive to toggle message panel
 **/
app.directive('slMessageToggle', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'C',
      scope: {},
      link: function (scope, element) {
        element.on('click', function () {
          $.sidr('close', 'MenuPanel');
          $('.sl-modal-mask').hide();
          $rootScope.showMessageForm();
        });
        element.on('$destroy', function () {
          element.off(); // Unbind events
        });
      },
    };
  },
]);
