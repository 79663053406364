import './services.stocks';
import { pick, get } from 'lodash-es';

app.controller('CheckStoreStockModal', [
  '$scope',
  '$uibModalInstance',
  '$filter',
  'stocksService',
  'pickUpAddresses',
  '@channel_warehouse_id_mapping',
  function (
    $scope,
    $uibModalInstance,
    $filter,
    stocksService,
    pickUpAddresses,
    channelWarehouseIdMapping,
  ) {
    function getStoreData(addresses) {
      const storeData = [];
      function extractData(levelData) {
        if (Array.isArray(levelData)) {
          levelData.forEach((level) => {
            extractData(level);
          });
          return;
        }

        if (levelData.next_level) {
          extractData(levelData.next_level);
          return;
        }

        storeData.push(levelData);
      }
      extractData(addresses);
      return storeData;
    }

    function getStoreDisplayData(storeData) {
      const displayData = storeData.reduce(
        (stores, store, i) => {
          const warehouseId = channelWarehouseIdMapping[store.channel_id];
          const targetStock = get($scope.stocks, warehouseId, {});
          const { saleable_quantity = 0, whitelisted_channel_ids = [] } =
            targetStock;

          const hasStock =
            $scope.product.out_of_stock_orderable ||
            $scope.product.unlimited_quantity ||
            (saleable_quantity > 0 &&
              whitelisted_channel_ids.includes(store.channel_id));

          const currentDisplayData = {
            id: i,
            name: $filter('translateModel')(store.store_name),
            address: $filter('translateModel')(store.store_address),
            hasStock,
          };
          stores[hasStock ? 'hasStock' : 'outOfStock'].push(currentDisplayData);
          return stores;
        },
        {
          hasStock: [],
          outOfStock: [],
        },
      );
      return [...displayData.hasStock, ...displayData.outOfStock];
    }

    function handleStockData() {
      $scope.isLoading = true;
      stocksService
        .getProductStock({
          root_product_id: $scope.product.root_product_id,
          ...($scope.variationSelected && {
            root_product_variation_ids: [
              $scope.variationSelected.root_product_variation_id,
            ],
          }),
        })
        .then(function (res) {
          const stocks = res.data.reduce((stocks, stock) => {
            stocks[stock.warehouse_id] = pick(stock, [
              'saleable_quantity',
              'whitelisted_channel_ids',
            ]);
            return stocks;
          }, {});
          $scope.stocks = stocks;
          $scope.stores = getStoreDisplayData(getStoreData(pickUpAddresses));
        })
        .finally(function () {
          $scope.isLoading = false;
        });
    }

    // state start
    $scope.isLoading = true;
    $scope.stores = [];
    $scope.stocks = {};
    // state end

    $scope.closeModal = function () {
      $uibModalInstance.close();
    };

    function init() {
      handleStockData();
    }

    init();
  },
]);
