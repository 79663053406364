app.service('promotionService', [
  '$http',
  function ($http) {
    const checkRedeemGiftPromotionExist = () => {
      return $http({
        url: 'api/promotions/is_redeem_gift_promotion_exist',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const getCoupons = (scope) => {
      return $http({
        method: 'GET',
        url: `/api/users/promotions?scope=${scope}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const getCouponCenterCoupons = () => {
      return $http({
        method: 'GET',
        url: `/promotions/available_draw_coupons`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const claimCouponByCode = (code) => {
      return $http({
        method: 'GET',
        url: `/user_coupons/claim_by_code?code=${code}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    const claimCouponById = (id) => {
      return $http({
        method: 'GET',
        url: `/user_coupons/claim_by_promotion_id?promotion_id=${id}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };

    return {
      checkRedeemGiftPromotionExist,
      getCoupons,
      getCouponCenterCoupons,
      claimCouponByCode,
      claimCouponById,
    };
  },
]);
