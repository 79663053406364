(function () {
  'use strict';
  angular
    .module('shop_app')
    .controller('ProductsFetchController', ProductsFetchController);

  ProductsFetchController.$inject = ['$scope', '$location'];

  function ProductsFetchController($scope, $location) {
    var currentQueryParams = $location.search();
    $scope.fetchQuery = currentQueryParams.limit || '24';

    var appendNewQueryToUrl = function () {
      Object.assign(currentQueryParams, {
        limit: $scope.fetchQuery,
      });
      $location.search(currentQueryParams);
      window.location.href = $location.absUrl();
    };

    $scope.$watch('fetchQuery', function (newValue, oldValue) {
      if (oldValue !== newValue && ['24', '48', '72'].includes(newValue)) {
        appendNewQueryToUrl();
      }
    });
  }
})();
