// TODO: facebook banner should also be implemented in this controller
app.controller('InAppBrowserPopupController', [
  '$scope',
  '$rootScope',
  '$cookies',
  'slFeatureService',
  function ($scope, $rootScope, $cookies, slFeatureService) {
    $scope.canShowIgHint =
      slFeatureService.hasFeature('ig_browser_notify') &&
      !slFeatureService.hasFeature('ig_browser_disabled');

    const setCookieCosentPopupDisplay = (value) => {
      const cookieConsentPopup = document.getElementById(
        'cookie-consent-popup',
      );
      if (cookieConsentPopup) {
        cookieConsentPopup.style.display = value;
      }
    };

    $scope.$watch(
      () => $rootScope.showMissingSessionKeyWarning,
      () => {
        if ($scope.canShowIgHint && $rootScope.showMissingSessionKeyWarning) {
          setCookieCosentPopupDisplay('none');
        }
      },
    );

    $scope.handleClose = () => {
      $cookies.put('show_missing_session_key_warning', 'false');
      $rootScope.showMissingSessionKeyWarning = false;

      setCookieCosentPopupDisplay('block');
    };
  },
]);
