app.directive('backInStockButton', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      scope: {
        product: '=',
        wishlistItem: '=',
        isItemInBackInStock: '=',
      },
      link: function ($scope) {
        function updateView() {
          $scope.isItemInBackInStock = checkIsBackInStockItemActive();
        }

        var checkIsBackInStockItemActive = function () {
          if (!$rootScope.isUserLoggedIn) {
            return false;
          }

          return !_.isEmpty($scope.wishlistItem)
            ? $scope.wishlistItem.is_subscribed_notify
            : false;
        };

        $scope.$watch('wishlistItem', updateView);
      },
    };
  },
]);
