import './services.ga';
import './services.slpixel';
app.directive('gaPageView', [
  'Analytics',
  'slPixelService',
  'gaService',
  function (Analytics, slPixelService, gaService) {
    return {
      restrict: 'A',
      scope: {},
      link: function () {
        if (slPixelService.impressions.length > 0) {
          slPixelService.sendProductImpressions();
        }
        if (Analytics.configuration.enhancedEcommerce) {
          gaService.sendPageView();
        }
      },
    };
  },
]);
