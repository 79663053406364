import './services.merchant';
app.service('stocksService', [
  '$http',
  'merchantService',
  function ($http, merchantService) {
    return {
      getProductStock: function ({
        root_product_id,
        root_product_variation_ids,
      }) {
        return $http.get(
          `/api/merchants/${merchantService.merchantId}/stocks`,
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            },
            params: {
              root_product_id,
              root_product_variation_ids,
            },
            paramSerializer: '$httpParamSerializerJQLike',
          },
        );
      },
    };
  },
]);
