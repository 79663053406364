app.service('userCreditService', [
  '$http',
  function ($http) {
    const getUserCreditRules = ({ rule_type } = {}) => {
      return $http({
        url: '/api/user_credit_rules',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: { rule_type },
      });
    };

    return {
      getUserCreditRules,
    };
  },
]);
